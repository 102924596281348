<template>
    <div>
        Welcome
    </div>
</template>

<script lang="ts">
export default {
    name: 'WelcomePage',
}
</script>

<style scoped>
</style>
