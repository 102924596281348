import Vue from 'vue'
import axios from 'axios'

function defaultThen(response) {
    if (response.status == 200) {
        let result = response.data
        if (result.code == 1000) {
            return result
        } else if (result.code == 2001) {
            Vue.prototype.$bus.$emit('doLogout')
        } else {
            console.log(result.msg)
        }
        return result
    } else {
        console.error(response.status)
    }
    return { code: 4000, msg: 'response status: ' + response.status }
}

const managerPath = '/oll-manager'

const http = {
    get: (uri, data) => {
        let params = ''
        if (data) {
            for (var key in data) {
                let value = encodeURI(data[key])
                if (params.length > 0) {
                    params += '&'
                } else {
                    params += '?'
                }
                params += key + '=' + value
            }
        }
        return axios.get(managerPath + uri + params).then(response => defaultThen(response))
    },
    post: (uri, data) => {
        return axios.post(managerPath + uri, data).then(response => defaultThen(response))
    },
    patch: (uri, id, data) => {
        return axios.patch(managerPath + uri + '/' + id, data).then(response => defaultThen(response))
    },
    put: (uri, id, data) => {
        return axios.patch(managerPath + uri + '/' + id, data).then(response => defaultThen(response))
    },
    del: (uri, id) => {
        return axios.delete(managerPath + uri + '/' + id).then(response => defaultThen(response))
    },
}

export default http
