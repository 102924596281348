import { render, staticRenderFns } from "./UEditor.vue?vue&type=template&id=24860d7a&scoped=true&"
import script from "./UEditor.vue?vue&type=script&lang=ts&"
export * from "./UEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24860d7a",
  null
  
)

export default component.exports