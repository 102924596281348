import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import VueRouter from 'vue-router'
import router from './router'
import http from './http'
import App from './App.vue'

Vue.use(ElementUI)
Vue.use(VueRouter)

new Vue({
    render: h => h(App),
    router: router,
    beforeCreate() {
        Vue.prototype.$bus = this
        Vue.prototype.$http = http
    },
}).$mount('#app')
