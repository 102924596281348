<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                ref="dataTable"
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :activeUrl="dataTable.activeUrl"
                :topButtons="dataTable.topButtons"
                :maxEditWinWidth="800"
                :maxEditWinHeight="400"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
        <DialogWin ref="uploadImageWin" :maxWidth="600" :maxHeight="300">
            <PageBox ref="uploadImageWinBox">
                <div style="text-align: center;">
                    <el-upload
                        ref="orgCarouselUploader"
                        class="upload-demo"
                        drag action="/oll-manager/user/carousel/uploadFile"
                        multiple
                        :limit="1"
                        :before-upload="onUploadBefore"
                        :on-success="onUploadFinished">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传 jpg,png 文件，且不超过1M</div>
                    </el-upload>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="editWin">
            <PageBox ref="editWinBox">
                <el-form :model="dataTable.editRow" label-width="80px" :rules="dataTable.editRules" ref="editForm">
                    <el-form-item label="图片" prop="imageUrl">
                        <input type="hidden" v-model="dataTable.editRow.id" />
                        <img width="100" height="100" class="edit-img" :src="dataTable.editRow.imageUrl || ' '" @click="toModifyImage" />
                    </el-form-item>
                    <el-form-item label="启用状态" prop="activeStatus">
                        <el-select v-model="dataTable.editRow.activeStatus">
                            <el-option value="Y" label="启用"></el-option>
                            <el-option value="N" label="禁用"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标题" prop="pageTitle">
                        <el-input type="text" v-model="dataTable.editRow.pageTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="内容" prop="contentHtml">
                        <div style="width: 800px;">
                            <UEditor ref="contentHtmlEditor" :onReady="contentHtmlEditorReady" />
                        </div>
                    </el-form-item>
                </el-form>
                <div style="text-align: center; padding-bottom: 20px;">
                    <el-button type="primary" @click="saveRow">保存</el-button>
                    <el-button @click="closeEdit">关闭</el-button>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DialogWin from '../components/DialogWin'
import DataTable from '../components/DataTable'
import UEditor from '../components/UEditor'

export default {
    name: 'OrgCarouselPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/carousel/list',
                titles: [
                    //{ prop: 'orgUserGroupId', label: '组ID' },
                    //{ prop: 'imageCode', label: '图片', type: 'html', filter: (value) => `<img src="${value}" />` },
                    { prop: 'imageUrl', label: '图片', type: 'html', filter: (value) => `<img height="100" src="${value}" />` },
                    { prop: 'activeStatus', label: '启用状态', width: 80, mapping: { 'Y': '启用', 'N': '禁用' } },
                ],
                //addUrlValue: '/user/carousel/add',
                //updateUrlValue: '/user/carousel/update',
                delUrlValue: '/user/carousel/del',
                activeUrlValue: '/user/carousel/active',
                actionsConfig: [
                    { label: '修改', plain: false, type: 'primary', width: 66, func: this.toEdit, pms: pmsConfig.PMS_CAROUSEL_UPDATE },
                ],
                actions: [],
                searchFormLines: [
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                    { prop: 'createDateBegin', label: '开始时间', type: 'date' },
                    { prop: 'createDateEnd', label: '结束时间', type: 'date' },
                ],
                topButtons: [
                    { type: 'primary', label: '新增', action: this.toEdit },
                ],
                /*
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'imageCode', label: '图片', type: 'hidden',  },
                    { prop: 'imageUrl', label: '图片', type: 'img', action: this.toModifyImage },
                    { prop: 'contentHtml', label: '内容', type: 'text' },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', rule: { required: true }, options: [
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                ],
                */
                editRow: {
                    imageUrl: '',
                },
                editRules: {
                    imageUrl: [{ required: true, message: '请选择图片' }],
                    activeStatus: [{ required: true, message: '请选择启用状态' }],
                },
            },
        }
    },
    methods: {
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        onUpdateUserPms() {
            //this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_CAROUSEL_ADD) ? this.dataTable.addUrlValue : ''
            //this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_CAROUSEL_UPDATE) ? this.dataTable.updateUrlValue : ''
            this.dataTable.delUrl = this.hasPms(pmsConfig.PMS_CAROUSEL_DELETE) ? this.dataTable.delUrlValue : ''
            this.dataTable.activeUrl = this.hasPms(pmsConfig.PMS_CAROUSEL_ACTIVE) ? this.dataTable.activeUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
        toModifyImage() {
            this.$refs.uploadImageWin.isShow = true
        },
        onUploadBefore() {
            this.$refs.uploadImageWinBox.loading = true
        },
        onUploadFinished(result) {
            if (result && result.code == '1000') {
                this.$refs.uploadImageWin.isShow = false
                this.$set(this.dataTable.editRow, 'imageCode', result.data.fileCode)
                this.$set(this.dataTable.editRow, 'imageUrl', result.data.fileUrl)
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
            this.$refs.orgCarouselUploader?.clearFiles()
            this.$refs.uploadImageWinBox.loading = false
        },
        toEdit(row) {
            this.$set(this.dataTable, 'editRow', { imageUrl: '' })
            if (row) {
                this.$set(this.dataTable, 'editRow', JSON.parse(JSON.stringify(row)))
            }
            if (this.$refs.contentHtmlEditor) {
                this.$refs.contentHtmlEditor.setContent(this.dataTable.editRow.contentHtml || '')
            }
            this.$refs.editWin.isShow = true
        },
        saveRow() {
            if (this.dataTable.editRow) {
                this.$refs.editForm.validate((valid) => {
                    if (!valid) {
                        console.log('valid fail!')
                        return false
                    }
                    this.dataTable.editRow.contentHtml =  this.$refs.contentHtmlEditor.getContent() || ''
                    if (this.dataTable.editRow.id && this.dataTable.editRow.id > 0) {
                        this.updateRecord(this.dataTable.editRow)
                    } else {
                        this.addRecord(this.dataTable.editRow)
                    }
                })
            }
        },
        updateRecord(row) {
            this.$http.patch('/user/carousel/update', row.id, row).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.$refs.dataTable.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        addRecord(row) {
            this.$http.post('/user/carousel/add', row).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.$refs.dataTable.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        closeEdit() {
            this.$set(this.dataTable, 'editRow', {})
            this.$refs.editWin.isShow = false
        },
        contentHtmlEditorReady(editor) {
            editor.setContent(this.dataTable.editRow.contentHtml)
        },
    },
    components: {
        PageBox,
        DialogWin,
        DataTable,
        UEditor,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
/deep/ .el-checkbox.is-disabled {
    display: none;
}
.edit-img {
    cursor: pointer;
    height: 100px;
    min-width: 100px;
}
</style>
