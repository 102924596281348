<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :activeUrl="dataTable.activeUrl"
                :maxEditWinWidth="800"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'

export default {
    name: 'ConfigPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/config/list',
                titles: [
                    { prop: 'groupCode', label: '配置组编码' },
                    { prop: 'propertyCode', label: '配置编码' },
                    { prop: 'propertyLabel', label: '配置名称' },
                ],
                addUrlValue: '/user/config/add',
                updateUrlValue: '/user/config/update',
                delUrlValue: '/user/config/del',
                actionsConfig: [],
                actions: [],
                searchFormLines: [
                    { prop: 'groupCode', label: '配置组名', type: 'text' },
                    { prop: 'propertyLabel', label: '配置名称', type: 'text' },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'groupCode', label: '配置组编码', type: 'text', rule: { required: true, min: 1, max: 32 } },
                    { prop: 'propertyCode', label: '配置编码', type: 'text', rule: { required: true, min: 1, max: 100 } },
                    { prop: 'propertyLabel', label: '配置名称', type: 'text', rule: { required: true, min: 1, max: 100 } },
                    { prop: 'propertyValue', label: '配置值', type: 'textarea', rule: { required: false, min: 0, max: 1000 } },
                    { prop: 'propertyDesc', label: '配置说明', type: 'textarea', rule: { required: false, min: 0, max: 1000 } },
                ],
            },
        }
    },
    methods: {
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        onUpdateUserPms() {
            this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_ADMIN) ? this.dataTable.addUrlValue : ''
            this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_ADMIN) ? this.dataTable.updateUrlValue : ''
            this.dataTable.delUrl = this.hasPms(pmsConfig.PMS_ADMIN) ? this.dataTable.delUrlValue : ''
            this.dataTable.activeUrl = this.hasPms(pmsConfig.PMS_ADMIN) ? this.dataTable.activeUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
    },
    components: {
        PageBox,
        DataTable,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
/deep/ .el-checkbox.is-disabled {
    display: none;
}
</style>
