<template>
    <div>
        <DialogWin ref="editWin" width="90%" height="90%" :maxWidth="1000" :onOpen="onOpen">
            <PageBox ref="editPageBox">
                <el-form ref="form" :model="questionData.questionForm" :inline="false" label-width="80px">

                    <div v-if="showQuestionType">
                        <div style="padding: 10px; font-weight: bold;">
                            <span>题型</span>
                        </div>
                        <div style="padding: 0 20px;">
                            <el-select v-model="questionData.questionForm.questionType">
                                <el-option v-for="option in questionTypeOptions" :key="option.value" :value="option.value" :label="option.label"></el-option>
                            </el-select>
                        </div>
                    </div>

                    <div>
                        <div style="padding: 10px; font-weight: bold;">
                            <span>题目</span>
                        </div>
                        <div style="padding: 0 20px;">
                            <UEditor ref="questionEditor" :onReady="questionEditorReady" />
                        </div>
                    </div>
                    <div>

                        <!-- 填空 -->
                        <div v-if="questionData.questionForm.questionType == 1">
                            <div style="padding: 10px; font-weight: bold;">
                                <span>答案</span>
                            </div>
                            <div class="answerBox">
                                <el-table :data="questionData.questionForm.answerOptions" style="width: 100%">
                                    <el-table-column prop="answerText" label="答案" :show-overflow-tooltip="true">
                                        <template slot-scope="scope">
                                            <span>{{ htmlToText(scope.row.answerText) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="140">
                                        <template scope="scope">
                                            <el-button type="primary" size="mini" icon="el-icon-edit" @click="toEditAnswerOption(scope.row)"></el-button>
                                            <el-button type="danger" size="mini" icon="el-icon-delete" @click="delAnswerOption(scope.row)"></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="addOptionBtnBox">
                                    <el-button type="primary" size="mini" @click="toEditAnswerOption()">添加选项</el-button>
                                </div>
                            </div>
                        </div>

                        <!-- 判断 -->
                        <div v-if="questionData.questionForm.questionType == 2">
                            <div style="padding: 10px; font-weight: bold;">
                                <span>判断选项</span>
                            </div>
                            <div class="answerBox">
                                <div style="padding: 10px 0;">
                                    <el-radio v-model="questionData.questionForm.answerYesOrNo" label="Y">对</el-radio>
                                </div>
                                <div style="padding: 5px 0;">
                                    <el-radio v-model="questionData.questionForm.answerYesOrNo" label="N">错</el-radio>
                                </div>
                            </div>
                        </div>

                        <!-- 单选，多选，不定项选择 -->
                        <div v-if="questionData.questionForm.questionType == 3 || questionData.questionForm.questionType == 4 || questionData.questionForm.questionType == 5">
                            <div style="padding: 10px; font-weight: bold;">
                                <span>单选选项</span>
                            </div>
                            <div class="answerBox">
                                <el-table :data="questionData.questionForm.answerOptions" style="width: 100%">
                                    <el-table-column label="正确答案" width="80">
                                        <template scope="scope">
                                            <input type="radio" name="answerRadio" :value="scope.row.id" v-model="questionData.questionForm.answerRadioValue" v-if="questionData.questionForm.questionType == 3" />
                                            <input type="checkbox" name="answerCheckbox" :value="scope.row.id" v-model="questionData.questionForm.answerCheckboxValue" v-if="questionData.questionForm.questionType == 4" />
                                            <input type="checkbox" name="answerCheckbox" :value="scope.row.id" v-model="questionData.questionForm.answerCheckboxValue" v-if="questionData.questionForm.questionType == 5" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="answerText" label="答案文本" :show-overflow-tooltip="true">
                                        <template slot-scope="scope">
                                            <div v-html="scope.row.answerText"></div>
                                        </template></el-table-column>
                                    <el-table-column label="操作" width="140">
                                        <template scope="scope">
                                            <el-button type="primary" size="mini" icon="el-icon-edit" @click="toEditAnswerOption(scope.row)"></el-button>
                                            <el-button type="danger" size="mini" icon="el-icon-delete" @click="delAnswerOption(scope.row)"></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="addOptionBtnBox">
                                    <el-button type="primary" size="mini" @click="toEditAnswerOption()">添加选项</el-button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-show="!haseChildren(questionData.questionForm.questionType)">
                        <div style="padding: 10px; font-weight: bold;">
                            <span>答案解析</span>
                        </div>
                        <div style="padding: 0 20px;">
                            <UEditor ref="answerExplainEditor" :onReady="answerExplainEditorReady" />
                        </div>
                    </div>
                </el-form>
                <div style="text-align: center; padding: 20px;">
                    <el-button type="primary" @click="saveQuestion">保存</el-button>
                    <el-button @click="onCancelEdit">取消</el-button>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="singleAnswerWin" title="答案文本编辑" width="800px" height="400px">
            <div style="padding: 20px;">
                <el-form ref="singleAnswerForm" label-width="80px">
                    <div style="padding: 10px; font-weight: bold;">
                        <span>答案文本</span>
                    </div>
                    <div style="padding: 0 20px 20px 20px;">
                        <UEditor ref="answerEditor" :onReady="answerEditorReady" />
                    </div>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="doEditAnswerOption()">确定</el-button>
                        <el-button @click="$refs.singleAnswerWin.isShow = false">取消</el-button>
                    </div>
                </el-form>
            </div>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import PageBox from '../components/PageBox'
import DialogWin from '../components/DialogWin'
import UEditor from '../components/UEditor'

export default {
    name: 'QuestionEditView',
    props: {
        showQuestionType: Boolean,
        onCancel: Function,
        onSaved: Function,
    },
    data() {
        return {
            updateCount: 0,
            questionData: {
                questionGroup: {},
                questionForm: {},
            },
            isQuestionEditorReady: false,
            isAnswerExplainEditor: false,
            isAnswerEditorReady: false,
            questionTypeOptions: [
            /*
                { value: 1, label: '填空' },
                { value: 2, label: '判断' },
                { value: 3, label: '单选' },
                { value: 4, label: '多选' },
                { value: 5, label: '不定项选择' },
                { value: 1101, label: '阅读理解', hasChildren: true },
            */
            ],
            openCallBack: null,
        }
    },
    watch: {
        updateCount() {
            if (this.isQuestionEditorReady) {
                this.$refs.questionEditor?.setContent(this.questionData.questionForm.questionText || '')
            }
            if (this.isAnswerExplainEditor) {
                this.$refs.answerExplainEditor?.setContent(this.questionData.questionForm.answerExplain || '')
            }
            if (this.isAnswerEditorReady) {
                this.$refs.answerEditor?.setContent(this.questionData.questionForm.formAnswerOptionText || '')
            }
        }
    },
    methods: {
        openWin(callback) {
            this.openCallBack = callback
            this.$refs.editWin.isShow = true
        },
        setQuestionData(questionGroup, question) {
            let questionGroupData = {}
            let questionData = {}
            if (questionGroup) {
                questionGroupData = JSON.parse(JSON.stringify(questionGroup))
            }
            if (question) {
                questionData = JSON.parse(JSON.stringify(question))
            }
            let opt = this.questionTypeOptions.filter(it => it.value == questionGroupData.questionType)[0]
            if (!questionData.questionType && opt && !opt.hasChildren) {
                questionData.questionType = questionGroupData.questionType
            }
            if ([2, 3, 4, 5].includes(questionData.questionType)) {
                let rightQuestions = questionData.answerOptions?.filter(item => item.right)
                if (rightQuestions && rightQuestions.length > 0) {
                    if (questionData.questionType == 2) {
                        questionData.answerYesOrNo = rightQuestions[0].answerText
                    }
                    if (questionData.questionType == 3) {
                        questionData.answerRadioValue = rightQuestions[0].id
                    }
                    if (questionData.questionType == 4 || questionData.questionType == 5) {
                        questionData.answerCheckboxValue = rightQuestions.map(item => item.id)
                    }
                }
            }
            this.questionData.questionGroup = questionGroup
            this.resetQuestionForm()
            if (questionData) {
                let keys = Object.keys(questionData)
                keys.forEach(key => this.questionData.questionForm[key] = questionData[key])
            }
            this.updateCount++
        },
        htmlToText(questionText) {
            let maxLength = 50
            let div = document.createElement('div')
            div.innerHTML = questionText
            let text = div.innerText
            text = text.length <= maxLength ? text : text.substring(0, maxLength) + '...'
            return text
        },
        onOpen() {
            if (this.openCallBack) {
                this.openCallBack(this)
            }
        },
        questionEditorReady() {
            this.isQuestionEditorReady = true
            this.$refs.questionEditor?.setContent(this.questionData.questionForm.questionText || '')
        },
        answerExplainEditorReady() {
            this.isAnswerExplainEditor = true
            this.$refs.answerExplainEditor?.setContent(this.questionData.questionForm.answerExplain || '')
        },
        answerEditorReady() {
            this.isAnswerEditorReady = true
            this.$refs.answerEditor?.setContent(this.questionData.questionForm.formAnswerOptionText || '')
        },
        haseChildren(questionType) {
            let opt = this.questionTypeOptions.filter(it => it.value == questionType)[0]
            if (opt && opt.hasChildren) {
                return true
            }
            return false
        },
        toEditAnswerOption(answerOption) {
            if (answerOption) {
                this.questionData.questionForm.formAnswerOptionId = answerOption.id
                this.questionData.questionForm.formAnswerOptionText = answerOption.answerText
            } else {
                this.questionData.questionForm.formAnswerOptionId = ''
                this.questionData.questionForm.formAnswerOptionText = ''
            }
            this.answerEditorReady()
            this.$refs.singleAnswerWin.isShow = true
        },
        delAnswerOption(row) {
            if (this.questionData.questionForm.answerOptions) {
                let targetIndex = -1
                this.questionData.questionForm.answerOptions.forEach((item, index) => {
                    if (item.id == row.id) {
                        targetIndex = index
                    }
                })
                if (targetIndex >= 0) {
                    this.$confirm('你确定要删除这个选项吗？', '提示', {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消',
                        confirmButtonClass: 'el-button--danger',
                        cancelButtonClass: 'btn-custom-cancel',
                        type: 'warning',
                    }).then(() => {
                        this.questionData.questionForm.answerOptions.splice(targetIndex, 1)
                    }).catch(() => {
                    })
                }
            }
        },
        resetQuestionForm() {
            this.questionData.questionForm = {
                id: 0,
                questionType: '',
                questionText: '',
                answerExplain: '',
                answerOptions: [], // 选项信息
                answerYesOrNo: '', // 判断答案
                // 临时表单数据
                formAnswerOptionId: '', // 正在编辑的答案选项ID
                formAnswerOptionText: '', // 正在编辑的答案文本
                answerRadioValue: '', // 单选正确答案radio显示值
                answerCheckboxValue: [], // 多选，不定项选择正确答案checkbox显示值
            }
        },
        onCancelEdit() {
            this.$refs.editWin.isShow = false
            this.resetQuestionForm()
            if (this.onCancel) {
                this.onCancel()
            }
        },
        saveQuestion() {
            let row = this.questionData.questionForm
            row.questionText = this.$refs.questionEditor.getContent()
            row.answerExplain = this.$refs.answerExplainEditor.getContent()
            row.paperId = this.paperId
            if (row.id && row.id > 0) {
                this.updateQuestion(row)
            } else {
                row.questionGroupId = this.questionData.questionGroup.id
                this.addQuestion(row)
            }
        },
        addQuestion(row) {
            this.$refs.editPageBox.loading = true
            this.$http.post('/user/questions/add', row).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.resetQuestionForm()
                    if (this.onSaved) {
                        this.onSaved()
                    }
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.editPageBox.loading = false
            })
        },
        updateQuestion(row) {
            this.$refs.editPageBox.loading = true
            this.$http.patch('/user/questions/update', row.id, row).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.resetQuestionForm()
                    if (this.onSaved) {
                        this.onSaved()
                    }
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.editPageBox.loading = false
            })
        },
        doEditAnswerOption() {
            this.questionData.questionForm.formAnswerOptionText = this.$refs.answerEditor.getContent()
            let answerOption = null
            if (!this.questionData.questionForm.answerOptions) {
                this.$set(this.questionData.questionForm, 'answerOptions', [])
            }
            let list = this.questionData.questionForm.answerOptions.filter(item => item.id == this.questionData.questionForm.formAnswerOptionId)
            if (list.length > 0) {
                answerOption = list[0]
            } else if (answerOption == null) {
                answerOption = {
                    id: Date.now(),
                    answerText: this.questionData.questionForm.formAnswerOptionText,
                    isRight: false,
                }
                this.questionData.questionForm.answerOptions.push(answerOption)
            }
            answerOption.answerText = this.questionData.questionForm.formAnswerOptionText
            this.$refs.singleAnswerWin.isShow = false
        },
    },
    components: {
        PageBox,
        DialogWin,
        UEditor,
    },
    mounted() {
        this.$http.get('/user/questionType/getQuestionTypes').then((result) => {
            if (result.code == 1000) {
                this.questionTypeOptions.splice(0)
                result.data.questionTypes?.forEach(questionType => {
                    if (!questionType.hasChildren && questionType.value < 100) {
                        this.questionTypeOptions.push(questionType)
                    }
                })
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
        })
    }
}
</script>

<style scoped>
.answerBox {
    padding: 0 20px;
}
.addOptionBtnBox {
    margin-top: 10px;
}
</style>
