<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :maxEditWinWidth="800"
                :maxEditWinHeight="300"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
        <DialogWin ref="rolesWin" title="编辑" :onOpen="loadSubRoles" :maxWidth="800">
            <PageBox ref="rolesWinBox" :defLoading="true">
                <div style="padding: 20px; background-color: white;">
                    <div>
                        <el-table :data="subRoles" style="width: 100%">
                            <el-table-column label="可以创建的角色">
                                <el-checkbox slot-scope="scope" v-model="selectedRoleIds" :label="scope.row.id">{{scope.row.roleName}}</el-checkbox>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="padding-top: 20px; text-align: center;">
                        <el-button type="primary" @click="saveSubRoles">保存</el-button>
                        <el-button @click="cancelRolePms">取消</el-button>
                    </div>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'
import DialogWin from '../components/DialogWin'

export default {
    name: 'RoleHierarchyPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/roleHierarchy/list',
                titles: [
                    { prop: 'roleCode', label: '角色编码' },
                    { prop: 'roleName', label: '角色名' },
                    { prop: 'activeStatus', label: '启用状态', mapping: { 'Y': '启用', 'N': '禁用' } },
                ],
                actionsConfig: [
                    { label: '设置', width: 66, func: this.modifySubRoles, pms: pmsConfig.PMS_ROLE_HIERARCHY_UPDATE },
                ],
                actions: [],
                searchFormLines: [
                    { prop: 'roleCode', label: '角色编码', type: 'text' },
                    { prop: 'roleName', label: '角色名', type: 'text' },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                ],
            },
            editPmsRow: {},
            subRoles: [],
            selectedRoleIds: [],
        }
    },
    methods: {
        modifySubRoles(row) {
            this.editPmsRow = JSON.parse(JSON.stringify(row))
            this.subRoles = []
            this.selectedRoleIds = []
            this.$refs.rolesWin.isShow = true
        },
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        loadSubRoles() {
            let roleId = this.editPmsRow.id
            this.$refs.rolesWinBox.loading = true
            this.$http.get('/user/roleHierarchy/getSubRoles', { roleId }).then((result) => {
                if (result.code == 1000) {
                    this.subRoles = result.data.roles
                    this.selectedRoleIds = result.data.subRoleIds
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.rolesWinBox.loading = false
            })
        },
        saveSubRoles() {
            let roleId = this.editPmsRow.id
            this.$refs.rolesWinBox.loading = true
            this.$http.patch('/user/roleHierarchy/saveSubRoles', roleId, { subRoleIds: this.selectedRoleIds }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.rolesWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.rolesWinBox.loading = false
            })
        },
        cancelRolePms() {
            this.$refs.rolesWin.isShow = false
        },
        onUpdateUserPms() {
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
    },
    components: {
        PageBox,
        DataTable,
        DialogWin,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
</style>
