<template>
    <PageBox ref="orgInfoPageBox">
        <el-form ref="form" :model="orgInfoForm" label-width="80px">
            <el-form-item label="学校名称">
                <el-input type="text" :value="orgInfoForm.orgName" disabled></el-input>
            </el-form-item>
            <el-form-item label="学校简介">
                <UEditor ref="orgIntroductionEditor" :onReady="onOrgInfoEditorReady" />
            </el-form-item>
            <div style="padding: 20px; text-align: center;">
                <el-button type="primary" @click="saveOrgInfo">保存</el-button>
            </div>
        </el-form>
    </PageBox>
</template>

<script lang="ts">
import PageBox from '../components/PageBox'
import UEditor from '../components/UEditor'

export default {
    name: 'OrgInfoPage',
    data() {
        return {
            orgInfoForm: {
                orgName: '',
                introduction: '',
            },
        }
    },
    methods: {
        saveOrgInfo() {
            this.orgInfoForm.introduction = this.$refs.orgIntroductionEditor.getContent()
            this.$refs.orgInfoPageBox.loading = true
            this.$http.post('/user/org/saveOrgInfo', this.orgInfoForm).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        message: '加载成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.orgInfoPageBox.loading = false
            })
        },
        refreshInfoEditor() {
            this.$refs.orgIntroductionEditor.setContent(this.orgInfoForm.introduction)
        },
        onOrgInfoEditorReady() {
            this.refreshInfoEditor()
        },
        loadOrgInfo() {
            this.$refs.orgInfoPageBox.loading = true
            this.$http.get('/user/org/getOrgInfo').then((result) => {
                if (result.code == 1000) {
                    const orgInfo = result.data.orgInfo || {}
                    Object.keys(orgInfo).forEach(key => {
                        this.$set(this.orgInfoForm, key, orgInfo[key])
                    })
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.orgInfoPageBox.loading = false
            })
        }
    },
    watch: {
        orgInfoForm: {
            deep: true,
            handler() {
                this.refreshInfoEditor()
            }
        }
    },
    components: {
        PageBox,
        UEditor,
    },
    mounted() {
        this.loadOrgInfo()
    }
}
</script>

<style scoped>
</style>
