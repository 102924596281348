<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :activeUrl="dataTable.activeUrl"
                :maxEditWinWidth="800"
                :maxEditWinHeight="250"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
        <DialogWin ref="pmsWin" title="权限" :onOpen="loadPmsTree" :maxWidth="800">
            <PageBox ref="pmsWinBox" :defLoading="true">
                <div style="padding: 20px; background-color: white;">
                    <div>
                        <el-tree ref="pmsTree"
                            :data="pmsTree"
                            node-key="code"
                            default-expand-all
                            show-checkbox
                        />
                    </div>
                    <div style="padding-top: 20px; text-align: center;">
                        <el-button type="primary" @click="saveRolePms">保存</el-button>
                        <el-button @click="cancelRolePms">取消</el-button>
                    </div>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'
import DialogWin from '../components/DialogWin'

export default {
    name: 'RolePage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/role/list',
                titles: [
                    { prop: 'roleCode', label: '角色编码' },
                    { prop: 'roleName', label: '角色名' },
                    { prop: 'activeStatus', label: '启用状态', mapping: { 'Y': '启用', 'N': '禁用' } },
                ],
                addUrlValue: '/user/role/add',
                updateUrlValue: '/user/role/update',
                delUrlValue: '/user/role/del',
                activeUrlValue: '/user/role/active',
                actionsConfig: [
                    { label: '权限', width: 66, func: this.modifyPms, pms: pmsConfig.PMS_ROLE_PERMISSION_UPDATE },
                ],
                actions: [],
                searchFormLines: [
                    { prop: 'roleCode', label: '角色编码', type: 'text' },
                    { prop: 'roleName', label: '角色名', type: 'text' },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'roleName', label: '角色名', type: 'text', rule: { required: true, min: 1, max: 32 } },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', rule: { required: true }, options: [
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                ],
            },
            editPmsRow: {},
            pmsTree: [],
            selectedPms: [],
        }
    },
    methods: {
        modifyPms(row) {
            this.editPmsRow = JSON.parse(JSON.stringify(row))
            this.pmsTree = []
            this.selectedPms = []
            this.$refs.pmsWin.isShow = true
        },
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        loadPmsTree() {
            let roleId = this.editPmsRow.id
            this.$refs.pmsWinBox.loading = true
            this.$http.get('/user/pms/tree', { roleId }).then((result) => {
                if (result.code == 1000) {
                    this.disabledTreeMenu(result.data.tree)
                    this.pmsTree = result.data.tree
                    this.selectedPms = result.data.pmsKeys
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.pmsWinBox.loading = false
            })
        },
        disabledTreeMenu(tree) {
            if (tree && tree.length > 0) {
                tree.forEach(item => {
                    item.disabled = item.type != 1
                    this.disabledTreeMenu(item.children)
                })
            }
        },
        getSelectedPmsKeys() {
            return this.$refs.pmsTree?.getCheckedKeys(true)
        },
        saveRolePms() {
            let pmsKeys = this.getSelectedPmsKeys()
            let roleId = this.editPmsRow.id
            this.$refs.pmsWinBox.loading = true
            this.$http.post('/user/pms/updateRolePms', { roleId, pmsKeys }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.pmsWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.pmsWinBox.loading = false
            })
        },
        cancelRolePms() {
            this.$refs.pmsWin.isShow = false
        },
        onUpdateUserPms() {
            this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_ROLE_ADD) ? this.dataTable.addUrlValue : ''
            this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_ROLE_UPDATE) ? this.dataTable.updateUrlValue : ''
            this.dataTable.delUrl = this.hasPms(pmsConfig.PMS_ROLE_DELETE) ? this.dataTable.delUrlValue : ''
            this.dataTable.activeUrl = this.hasPms(pmsConfig.PMS_ROLE_ACTIVE) ? this.dataTable.activeUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
    },
    watch: {
        selectedPms() {
            this.$refs.pmsTree?.setCheckedKeys(this.selectedPms)
        },
    },
    components: {
        PageBox,
        DataTable,
        DialogWin,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
/deep/ .el-checkbox.is-disabled {
    display: none;
}
</style>
