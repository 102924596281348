export const menuConfig = {
    data() {
        return {
            menuConfig: {
                // index
                'M-index': {
                    icon: 'el-icon-monitor',
                    uri: '/welcome',
                },
                // menus
                'M01': {
                    icon: 'el-icon-user',
                },
                'M0101': {
                    uri: '/account/student-list',
                },
                'M0102': {
                    uri: '/account/recruiter-list',
                },
                'M0103': {
                    uri: '/account/org-manager-list',
                },
                'M02': {
                    icon: 'el-icon-reading',
                },
                'M0201': {
                    uri: '/lessons/lessons-list',
                },
                'M0202': {
                    uri: '/question/paper-list',
                },
                'M0203': {
                    uri: '/question/video-list',
                },
                'M0204': {
                    uri: '/question/video-class',
                },
                'M0205': {
                    uri: '/question/paper-class',
                },
                'M03': {
                    icon: 'el-icon-document',
                },
                'M0301': {
                    uri: '/order/order-list',
                },
                'M0302': {
                    uri: '/order/order-review',
                },
                'M04': {
                    icon: 'el-icon-bank-card',
                },
                'M0401': {
                    uri: '/moneyLog/money-log-list',
                },
                // 机构管理
                'M11': {
                    icon: 'el-icon-school',
                },
                'M1101': {
                    uri: '/org/info',
                },
                'M1102': {
                    uri: '/org/carousel',
                },
                // settings
                'M99': {
                    icon: 'el-icon-setting',
                    uri: '/setting',
                },
                'M9991': {
                    uri: '/setting/user-list',
                },
                'M9992': {
                    uri: '/setting/role-list',
                },
                'M9993': {
                    uri: '/setting/role-hierarchy',
                },
                'M9994': {
                    uri: '/setting/user-group-list',
                },
                'M9995': {
                    uri: '/setting/version-list',
                },
                'M9999': {
                    uri: '/setting/config-list',
                },
            },
        }
    }
}
