<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
    </div>
</template>

<script lang="ts">
import { userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'

export default {
    name: 'MoneyLogPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/moneyLog/list',
                titles: [
                    { prop: 'createDate', label: '记录时间', width: '180px' },
                    { prop: 'loginid', label: '金额账号' },
                    { prop: 'modifyTotalMoney', label: '交易金额' },
                    { prop: 'oldTotalMoney', label: '交易前金额' },
                    { prop: 'newTotalMoney', label: '交易后金额' },
                    { prop: 'logText', label: '记录备注' },
                    { prop: 'operatorLoginid', label: '操作账号' },
                ],
                actionsConfig: [],
                actions: [],
                searchFormLines: [
                    { prop: 'loginid', label: '金额账号', type: 'text' },
                    { prop: 'operatorLoginid', label: '操作账号', type: 'text' },
                    { prop: 'createDateBegin', label: '创建开始', type: 'date' },
                    { prop: 'createDateEnd', label: '创建结束', type: 'date' },
                ],
            },
        }
    },
    methods: {
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        onUpdateUserPms() {
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
    },
    components: {
        PageBox,
        DataTable,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
/deep/ .el-checkbox.is-disabled {
    display: none;
}
</style>
