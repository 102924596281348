<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                ref="dataTable"
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :activeUrl="dataTable.activeUrl"
                :maxEditWinWidth="800"
                :maxEditWinHeight="620"
                :loadListen="dataTableLoadListen"
                :params="dataTable"
            />
        </PageBox>
        <DialogWin ref="roleWin" :onOpen="loadRoles" :maxWidth="800">
            <PageBox ref="roleWinBox" :defLoading="true">
                <div style="padding: 20px; background-color: white;">
                    <div>
                        <el-table :data="roleList" style="width: 100%">
                            <el-table-column>
                                <el-radio slot-scope="scope" v-model="userRoleId" :label="scope.row.id">{{scope.row.roleName}}</el-radio>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="padding-top: 20px; text-align: center;">
                        <el-button type="primary" @click="saveUserRole">保存</el-button>
                        <el-button @click="cancelEditRole">取消</el-button>
                    </div>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="moneyWin" :maxWidth="800" :onOpen="onOpenMoneyView">
            <PageBox ref="moneyWinBox">
                <div style="padding: 20px; background-color: white;">
                    <div>
                        <el-form ref="editForm" :inline="false" :model="editRow" :rules="editFormRules" label-width="100px">
                            <input type="hidden" :value="editRow.version" />
                            <el-form-item label="总金额" prop="totalMoney">
                                <el-input-number v-model="editRow.totalMoney" placeholder="总金额" :precision="2" :min="0" :max="1000000"></el-input-number>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="padding-top: 20px; text-align: center;">
                        <el-button type="primary" @click="saveUserMoney">保存</el-button>
                        <el-button @click="cancelEditMoney">取消</el-button>
                    </div>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'
import DialogWin from '../components/DialogWin'

export default {
    name: 'OrgManagerAccountPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/orgManagerAccount/list',
                titles: [
                    { prop: 'loginid', label: '账号' },
                    { prop: 'userNickname', label: '昵称' },
                    { prop: 'userName', label: '姓名' },
                    { prop: 'roleName', label: '角色' },
                    { prop: 'userGroupName', label: '用户组' },
                    { prop: 'superiorLoginid', label: '创建者' },
                    //{ prop: 'totalMoney', label: '余额' },
                    { prop: 'activeStatus', label: '启用状态', mapping: { 'Y': '启用', 'N': '禁用' } },
                ],
                addUrlValue: '/user/orgManagerAccount/add',
                updateUrlValue: '/user/orgManagerAccount/update',
                activeUrlValue: '/user/orgManagerAccount/active',
                actionsConfig: [
                    { label: '修改金额', width: 100, func: this.modifyMoney, pms: pmsConfig.PMS_ACCOUNT_ORG_MANAGER_MONEY },
                ],
                actions: [],
                searchFormLines: [
                    { prop: 'loginid', label: '账号', type: 'text' },
                    { prop: 'userNickname', label: '昵称', type: 'text' },
                    { prop: 'userName', label: '姓名', type: 'text' },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                    { prop: 'superiorLoginid', label: '创建者', type: 'text' },
                    { prop: 'userGroupId', label: '用户组', type: 'select', options: 'searchGroupOptions' },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    //{ prop: 'version', label: 'version', type: 'hidden' },
                    { prop: 'loginid', label: '账号', type: 'text', rule: { required: true, min: 3, max: 32 } },
                    { prop: 'userNickname', label: '昵称', type: 'text', rule: { required: true, min: 1, max: 32 } },
                    { prop: 'userName', label: '姓名', type: 'text', rule: { required: false, min: 1, max: 32 } },
                    { prop: 'userGroupId', label: '用户组', type: 'select', rule: { required: true }, options: 'groupOptions' },
                    //{ prop: 'totalMoney', label: '余额', type: 'number', precision: 2, min: 0, max: 1000000, rule: { required: true } },
                    { type: 'button', btnLabel: '重置密码', action: this.toResetPwd, showIn: 'update', size: 'small', style: { backgroundColor: '#F56C6C', borderColor: '#F56C6C' } },
              ],
                roleOptions: [],
                searchRoleOptions: [],
                groupOptions: [],
                searchGroupOptions: [],
            },
            editRoleRow: {},
            roleList: [],
            userRoleId: 0,
            editRow: {},
            editFormRules: {
            },
        }
    },
    methods: {
        modifyRole(row) {
            this.editRoleRow = JSON.parse(JSON.stringify(row))
            this.roleList = [],
            this.userRoleId = 0,
            this.$refs.roleWin.isShow = true
        },
        loadRoles() {
            let userId = this.editRoleRow.id
            this.$refs.roleWinBox.loading = true
            this.$http.get('/user/role/getUserRoles', { userId }).then((result) => {
                if (result.code == 1000) {
                    this.roleList = result.data.allRoles
                    this.userRoleId = result.data.userRoleId
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.roleWinBox.loading = false
            })
        },
        loadGroupOptions() {
            if (this.hasPms(pmsConfig.PMS_ACCOUNT_ORG_MANAGER_LIST) || this.hasPms(pmsConfig.PMS_ACCOUNT_ORG_MANAGER_ADD) || this.hasPms(pmsConfig.PMS_ACCOUNT_ORG_MANAGER_UPDATE)) {
                this.$http.get('/user/userGroup/listGroups').then((result) => {
                    if (result.code == 1000) {
                        let groups = result.data.groups
                        if (groups && groups.length > 0) {
                            this.dataTable.groupOptions = []
                            this.dataTable.searchGroupOptions = [{ label: '' }]
                            groups.forEach(group => {
                                this.dataTable.groupOptions.push({ value: group.id, label: group.groupName })
                                this.dataTable.searchGroupOptions.push({ value: group.id, label: group.groupName })
                            })
                        }
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                })
            }
        },
        saveUserRole() {
            this.$refs.roleWin.loading = true
            this.$http.post('/user/role/updateUserRole', { userId: this.editRoleRow.id, roleId: this.userRoleId }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.roleWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.roleWinBox.loading = false
            })
        },
        cancelEditRole() {
            this.$refs.roleWin.isShow = false
        },
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        cancelRolePms() {
            this.$refs.roleWin.isShow = false
        },
        toResetPwd(row) {
            this.$confirm('此操作将重置该账号的密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.resetPwd(row)
            }).catch(() => {
            })
        },
        resetPwd(row) {
            this.$refs.dataTable.setEditLoading(true)
            this.$http.patch('/user/orgManagerAccount/resetPwd', row.id, {}).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.dataTable.setEditLoading(false)
            })
        },
        onUpdateUserPms() {
            this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_ACCOUNT_ORG_MANAGER_ADD) ? this.dataTable.addUrlValue : ''
            this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_ACCOUNT_ORG_MANAGER_UPDATE) ? this.dataTable.updateUrlValue : ''
            this.dataTable.activeUrl = this.hasPms(pmsConfig.PMS_ACCOUNT_ORG_MANAGER_ACTIVE) ? this.dataTable.activeUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
            this.loadGroupOptions()
        },
        modifyMoney(row) {
            this.editRow = JSON.parse(JSON.stringify(row))
            this.$refs.moneyWin.isShow = true
        },
        saveUserMoney() {
            this.$refs.moneyWin.loading = true
            this.$http.patch('/user/orgManagerAccount/modifyMoney', this.editRow.id, this.editRow).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.moneyWin.isShow = false
                    this.$refs.dataTable.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.moneyWinBox.loading = false
            })
        },
        cancelEditMoney() {
            this.$refs.moneyWin.isShow = false
        },
        onOpenMoneyView() {
            this.$refs.moneyWinBox.loading = true
            this.$http.get('/user/orgManagerAccount/getAccountInfo', { id: this.editRow.id }).then((result) => {
                if (result.code == 1000) {
                    this.editRow.totalMoney = result.data.accountInfo.totalMoney
                    this.editRow.version = result.data.accountInfo.version
                    this.$refs.moneyWinBox.loading = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
    },
    components: {
        PageBox,
        DataTable,
        DialogWin,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
</style>
