<template>
    <div>
        <div style="padding: 5px 0;" v-if="searchFormLines && searchFormLines.length > 0">
            <div style="padding: 20px; border: solid 1px #DDD;">
                <el-form :inline="searchFieldOneLine === true ? false : true" :model="searchForm" label-width="80px">
                    <el-form-item v-for="(searchItem, index) in searchFormLines" :key="index" :label="searchItem.label">
                        <el-input v-model="searchForm[searchItem.prop]" :placeholder="searchItem.label" v-if="searchItem.type == 'text'"></el-input>
                        <el-select v-model="searchForm[searchItem.prop]" :placeholder="searchItem.label" v-else-if="searchItem.type == 'select'">
                            <el-option :value="option.value" :label="option.label" v-for="option in (params && typeof(searchItem.options) == 'string' ? params[searchItem.options] : searchItem.options)" :key="option.value"></el-option>
                        </el-select>
                        <el-date-picker v-model="searchForm[searchItem.prop]" placeholder="选择日期" type="date" value-format="yyyy-MM-dd" v-else-if="searchItem.type == 'date'"></el-date-picker>
                        <el-input v-model="searchForm[searchItem.prop]" :placeholder="searchItem.label" v-else></el-input>
                    </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="onSearch">查询</el-button>
                        <el-button @click="resetSearchForm">重置</el-button>
                    </div>
                </el-form>
            </div>
        </div>
        <div style="padding: 5px 0;" v-if="addUrl || (showAdd && toAddFun) || (topButtons && topButtons.length > 0)">
            <el-button type="primary" v-if="addUrl || (showAdd && toAddFun)" @click="defaultToAdd">新增</el-button>
            <el-button v-for="(button, index) in topButtons" :key="index" :type="button.type" @click="() => { if (button.action) button.action() }">{{ button.label }}</el-button>
        </div>
        <div style="padding: 5px 0;">
            <el-table border :data="rows" style="width: 100%">
                <el-table-column type="index" label="序号" width="80" fixed="left" v-if="showIndex" />
                <el-table-column v-for="title in titles" :key="title.id" :label="title.label" :prop="title.prop" :show-overflow-tooltip="title.showOverflowTooltip" :width="title.width" :fixed="title.fixed">
                    <template slot-scope="scope">
                        <span v-if="title.type == 'html'" v-html="title.filter ? title.filter(scope.row[title.prop]) : ( title.mapping ? title.mapping[scope.row[title.prop]] : scope.row[title.prop] )"></span>
                        <span v-else>
                            {{ title.filter ? title.filter(scope.row[title.prop]) : ( title.mapping ? title.mapping[scope.row[title.prop]] : scope.row[title.prop] ) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" :width="columnWidth" fixed="right" v-if="delUrl || updateUrl || activeUrl || (actions && actions.length > 0)">
                    <template slot-scope="scope" v-if="scope.row['readOnly'] != 'Y' && (delUrl || updateUrl || activeUrl || (actions && actions.length > 0))">
                        <el-button :type="action.type ? action.type : ''" :plain="action.plain !== false" size="mini" v-for="(action, index) in actions" :key="index" @click="action.func(scope.row)" v-show="action.isShow ? action.isShow(scope.row) : true">
                            {{action.label}}
                        </el-button>
                        <el-button type="danger" size="mini" icon="el-icon-bottom" @click="activeRecord(scope.row)" v-if="activeUrl && scope.row.activeStatus == 'Y'"></el-button>
                        <el-button type="success" size="mini" icon="el-icon-top" @click="activeRecord(scope.row)" v-if="activeUrl && scope.row.activeStatus != 'Y'"></el-button>
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="toEdit(scope.row)" v-if="updateUrl"></el-button>
                        <el-button type="danger" size="mini" icon="el-icon-delete" @click="toDelete(scope.row)" v-if="delUrl"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div>
            <div style="padding: 5px 0;" v-if="pageBar">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[5, 10, 20, 50, 100, 200, 500]"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper" />
            </div>
        </div>
        <div v-if="addUrl || updateUrl">
            <DialogWin ref="editWin" title="编辑" :width="editWinWidth" :height="editWinHeight" :onOpen="onOpenEditWin" :onClose="onCloseEditWin" :maxWidth="maxEditWinWidth" :maxHeight="maxEditWinHeight">
                <PageBox ref="pageBox">
                    <div v-if="editFormLines && editFormLines.length > 0">
                        <el-form ref="editForm" :inline="false" :model="editRow" :rules="editFormRules" label-width="100px">
                            <div v-for="(editItem, index) in editFormLines" :key="index" :style="{ display: editItem.type != 'hidden' ? 'block' : 'none' }">
                                <el-form-item :label="editItem.label" :prop="editItem.prop">

                                    <input type="hidden" v-model="editRow[editItem.prop]" v-if="editItem.type == 'hidden' && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))" />

                                    <el-input v-model="editRow[editItem.prop]" :placeholder="editItem.label" v-else-if="editItem.type == 'text' && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))"></el-input>

                                    <el-input v-model="editRow[editItem.prop]" :placeholder="editItem.label" type="textarea" v-else-if="editItem.type == 'textarea' && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))"></el-input>

                                    <el-select v-model="editRow[editItem.prop]" :placeholder="editItem.label" v-else-if="editItem.type == 'select' && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))">
                                        <el-option :value="option.value" :label="option.label" v-for="option in (params && typeof(editItem.options) == 'string' ? params[editItem.options] : editItem.options)" :key="option.value"></el-option>
                                    </el-select>

                                    <el-input-number v-model="editRow[editItem.prop]" :placeholder="editItem.label" :precision="editItem.precision" :min="editItem.min" :max="editItem.max" v-else-if="editItem.type == 'number' && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))"></el-input-number>

                                    <el-button type="primary" @click="editItem.action(editRow)" :style="editItem.style" :size="editItem.size" v-else-if="editItem.type == 'button' && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))">{{editItem.btnLabel}}</el-button>

                                    <img class="edit-img" :src="editRow[editItem.prop] || editItem.defaultImage || ' '" @click="editItem.action(editRow)" v-if="(editItem.type == 'img' || editItem.type == 'image') && (!editItem.showIn || editItem.showIn == (editRow['id'] ? 'update' : 'add'))" />

                                </el-form-item>
                            </div>
                            <div style="text-align: center;">
                                <el-button type="primary" @click="saveEdit">保存</el-button>
                                <el-button @click="cancelEdit">取消</el-button>
                            </div>
                        </el-form>
                    </div>
                </PageBox>
            </DialogWin>
        </div>
    </div>
</template>

<script lang="ts">
import DialogWin from './DialogWin'
import PageBox from './PageBox'

export default {
    name: 'DataTable',
    props: {
        pn: Number,
        ps: Number,
        searchFieldOneLine: Boolean,
        showIndex: Boolean,
        pageBar: Boolean,
        titles: Array,
        dataUrl: String,
        searchFormLines: Array,
        editFormLines: Array,
        actions: Array,
        addUrl: String,
        updateUrl: String,
        delUrl: String,
        activeUrl: String,
        maxEditWinWidth: Number,
        maxEditWinHeight: Number,
        loadListen: Function,
        onOpenEditWin: Function,
        onCloseEditWin: Function,
        beforeSave: Function,
        toAddFun: Function,
        params: Object,
        baseSearchParams: Object,
        topButtons: Array,
    },
    data() {
        return {
            pageNum: 1,
            pageSize: 10,
            searchForm: {},
            total: 0,
            rows: [],
            editRow: {},
            editWinWidth: '80%',
            editWinHeight: '80%',
            requestKeySet: new Set(),
            editFormRules: {},
            showAdd: true,
        }
    },
    computed: {
        columnWidth() {
            let width = 0
            width += 12
            width += 54 * [this.delUrl,this.updateUrl,this.activeUrl].filter((it) => it).length
            this.actions?.forEach((action) => {
                width += parseInt(action.width) > 0 ? parseInt(action.width) : 0
            })
            return width
        },
    },
    methods: {
        setEditFormRules() {
            let result = {}
            this.editFormLines?.forEach((formItem) => {
                if (formItem.rule) {
                    let rules = result[formItem.prop] || []
                    if (formItem.rule.required) {
                        rules.push({ required: true, message: '请输入' + formItem.label, trigger: 'blur' })
                    }
                    if (parseInt(formItem.rule.min) >= 0) {
                        rules.push({ min: parseInt(formItem.rule.min), message: '长度必须大于' + parseInt(formItem.rule.min), trigger: 'blur' })
                    }
                    if (parseInt(formItem.rule.max) >= 0) {
                        rules.push({ max: parseInt(formItem.rule.max), message: '长度必须小于' + parseInt(formItem.rule.max), trigger: 'blur' })
                    }
                    result[formItem.prop] = rules
                }
            })
            this.editFormRules = result
        },
        firstPage() {
            this.pageNum = 1
        },
        refreshData() {
            let search = JSON.parse(JSON.stringify(this.searchForm))
            search.pn = this.pageNum
            search.ps = this.pageSize
            let loadKey = Date.now() + '-' + parseInt(Math.random() * 10000)
            this.startLoad(loadKey)
            let data = {}
            if (this.baseSearchParams) {
                Object.keys(this.baseSearchParams).forEach(key => {
                    data[key] = this.baseSearchParams[key]
                })
            }
            if (search) {
                Object.keys(search).forEach(key => {
                    data[key] = search[key]
                })
            }
            this.$http.get(this.dataUrl, data).then((result) => {
                if (result.code == 1000) {
                    let page = result.data.page
                    this.total = page.total
                    this.rows = page.records
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.stopLoad(loadKey)
            })
        },
        handleSizeChange(ps) {
            this.pageSize = ps
            this.setRouter()
        },
        handleCurrentChange(pn) {
            this.pageNum = pn
            this.setRouter()
        },
        setRouter() {
            //let query = {}
            /*
            query = JSON.parse(JSON.stringify(this.$router.history.current.query))
            for (var key in this.searchForm) {
                query[key] = this.searchForm[key]
            }
            */
            //query.ps = this.pageSize
            //query.pn = this.pageNum
            //this.$router.push({ query: query })
        },
        onSearch() {
            this.pageNum = 1
            this.refreshData()
        },
        resetSearchForm() {
            this.searchForm = {}
        },
        toDelete(row) {
            this.$confirm('此操作将永久删除该行记录, 是否继续?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.delRecord(row)
            }).catch(() => {
            })
        },
        delRecord(row) {
            let loadKey = Date.now() + '-' + parseInt(Math.random() * 10000)
            this.startLoad(loadKey)
            this.$http.del(this.delUrl, row.id).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                    this.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.stopLoad(loadKey)
            })
        },
        activeRecord(row) {
            let loadKey = Date.now() + '-' + parseInt(Math.random() * 10000)
            this.startLoad(loadKey)
            this.$http.patch(this.activeUrl, row.id, { activeStatus: row.activeStatus == 'Y' ? 'N' : 'Y' }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    })
                    this.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
            this.stopLoad(loadKey)
        },
        addRecord(row) {
            let loadKey = Date.now() + '-' + parseInt(Math.random() * 10000)
            this.startLoad(loadKey)
            this.setEditLoading(true)
            this.$http.post(this.addUrl, row).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.setEditLoading(false)
                this.stopLoad(loadKey)
            })
        },
        updateRecord(row) {
            let loadKey = Date.now() + '-' + parseInt(Math.random() * 10000)
            this.startLoad(loadKey)
            this.setEditLoading(true)
            this.$http.patch(this.updateUrl, row.id, row).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.setEditLoading(false)
                this.stopLoad(loadKey)
            })
        },
        saveEdit() {
            if (this.editRow) {
                this.$refs.editForm.validate((valid) => {
                    if (!valid) {
                        console.log('valid fail!')
                        return false
                    }
                    if (this.editRow.id > 0) {
                        if (this.beforeSave) {
                            this.beforeSave(this.editRow)
                        }
                        this.updateRecord(this.editRow)
                    } else {
                        if (this.beforeSave) {
                            this.beforeSave(this.editRow)
                        }
                        this.addRecord(this.editRow)
                    }
                })
            }
        },
        cancelEdit() {
            this.$refs.editWin.isShow = false
            this.editRow = {}
            this.$refs.editForm.resetFields()
        },
        defaultToAdd() {
            if (this.toAddFun) {
                this.toAddFun()
            } else {
                this.toAdd()
            }
        },
        toAdd() {
            this.editRow = {}
            this.$refs.editWin.isShow = true
        },
        toEdit(row) {
            this.editRow = JSON.parse(JSON.stringify(row))
            this.$refs.editWin.isShow = true
        },
        startLoad(key) {
            this.requestKeySet.add(key)
            this.updateLoad()
        },
        stopLoad(key) {
            this.requestKeySet.delete(key)
            this.updateLoad()
        },
        updateLoad() {
            if (this.loadListen) {
                this.loadListen(this.requestKeySet)
            }
        },
        setEditLoading(value) {
            this.$refs.pageBox.loading = value
        },
    },
    watch: {
        pageNum() {
            this.refreshData()
        },
        pageSize() {
            this.refreshData()
        },
    },
    components: {
        DialogWin,
        PageBox,
    },
    mounted() {
        this.pageSize = parseInt(this.ps) >= 1 ? parseInt(this.ps) : 5
        this.pageNum = parseInt(this.pn) >= 1 ? parseInt(this.pn) : 1
        this.refreshData()
        this.setEditFormRules()
    }
}
</script>

<style scoped>
.edit-img {
    cursor: pointer;
    height: 100px;
    min-width: 100px;
}
</style>
