const pmsConfig = {
    // 登录
    PMS_ADMIN: 'pms:admin',
    PMS_BACKGROUND: 'pms:background',
    PMS_FRONT: 'pms:front',
    // 角色
    PMS_ROLE_LIST: "pms:role:list",
    PMS_ROLE_ACTIVE: "pms:role:active",
    PMS_ROLE_DELETE: "pms:role:delete",
    PMS_ROLE_ADD: "pms:role:add",
    PMS_ROLE_UPDATE: "pms:role:update",
    PMS_ROLE_PERMISSION_UPDATE: "pms:role_permission:update",
    // 用户组
    PMS_USER_GROUP_LIST: "pms:userGroup:list",
    PMS_USER_GROUP_ACTIVE: "pms:userGroup:active",
    PMS_USER_GROUP_DELETE: "pms:userGroup:delete",
    PMS_USER_GROUP_ADD: "pms:userGroup:add",
    PMS_USER_GROUP_UPDATE: "pms:userGroup:update",
    // 账号
    PMS_ACCOUNT_LIST: "pms:account:list",
    PMS_ACCOUNT_ACTIVE: "pms:account:active",
    PMS_ACCOUNT_DELETE: "pms:account:delete",
    PMS_ACCOUNT_ADD: "pms:account:add",
    PMS_ACCOUNT_UPDATE: "pms:account:update",
    // 角色创建配置
    PMS_ROLE_HIERARCHY_LIST: "pms:roleHierarchy:list",
    PMS_ROLE_HIERARCHY_UPDATE: "pms:roleHierarchy:update",
    // 学生账号
    PMS_ACCOUNT_STUDENT_LIST: "pms:accountStudent:list",
    PMS_ACCOUNT_STUDENT_ACTIVE: "pms:accountStudent:active",
    PMS_ACCOUNT_STUDENT_ADD: "pms:accountStudent:add",
    PMS_ACCOUNT_STUDENT_UPDATE: "pms:accountStudent:update",
    // 招生员账号
    PMS_ACCOUNT_RECRUITER_LIST: "pms:accountRecruiter:list",
    PMS_ACCOUNT_RECRUITER_ACTIVE: "pms:accountRecruiter:active",
    PMS_ACCOUNT_RECRUITER_ADD: "pms:accountRecruiter:add",
    PMS_ACCOUNT_RECRUITER_UPDATE: "pms:accountRecruiter:update",
    // 机构管理员账号
    PMS_ACCOUNT_ORG_MANAGER_LIST: "pms:accountOrgManager:list",
    PMS_ACCOUNT_ORG_MANAGER_ACTIVE: "pms:accountOrgManager:active",
    PMS_ACCOUNT_ORG_MANAGER_ADD: "pms:accountOrgManager:add",
    PMS_ACCOUNT_ORG_MANAGER_UPDATE: "pms:accountOrgManager:update",
    PMS_ACCOUNT_ORG_MANAGER_MONEY: "pms:accountOrgManager:money",
    // 课程管理
    PMS_LESSONS_MANAGER: "pms:lessons:manager",
    PMS_QUESTIONS_MANAGER: "pms:questions:manager",
    PMS_VIDEO_MANAGER: "pms:video:manager",
    PMS_QUESTIONS_CLASS_MANAGER: "pms:questionsClass:manager",
    PMS_VIDEO_CLASS_MANAGER: "pms:videoClass:manager",
    // 订单
    PMS_ORDER_LIST: "pms:order:list",
    PMS_ORDER_ADD: "pms:order:add",
    PMS_ORDER_REVIEW: "pms:order:review",
    // 机构信息
    PMS_ORG_INFO_UPDATE: "pms:orgInfo:update",
    // 轮播图
    PMS_CAROUSEL_LIST: "pms:carousel:list",
    PMS_CAROUSEL_ADD: "pms:carousel:add",
    PMS_CAROUSEL_UPDATE: "pms:carousel:update",
    PMS_CAROUSEL_DELETE: "pms:carousel:delete",
    PMS_CAROUSEL_ACTIVE: "pms:carousel:active",
}

const userPms = {
    data() {
        return {
            userPms: [],
        }
    },
    methods: {
        hasPms(pms) {
            return this.userPms.includes(pmsConfig.PMS_ADMIN) || this.userPms.includes(pms)
        },
        onUpdateUserPms(userPms) {
            this.userPms = userPms
        },
    },
    mounted() {
        this.$http.get('/user/pms/getUserPms').then((result) => {
            if (result.code == 1000) {
                let userPms = result.data.userPms || []
                this.userPms = userPms
                this.onUpdateUserPms(userPms)
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
        })
    }
}

export { pmsConfig, userPms }
