<template>
    <div class="rootDiv">
        <el-tree
            ref="elTree"
            :data="tree"
            node-key="id"
            :default-expand-all="openAll"
            :default-expanded-keys="expandedKeys"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
            @node-contextmenu="nodeMenu"
            @node-click="nodeClick"
            @check="nodeCheck"
            :draggable="edit"
            :show-checkbox="showCheckbox"
            @node-drop="dropDone"
            :allow-drop="allowDrop"
            :expand-on-click-node="false">
            <div class="custom-tree-node" slot-scope="{ node, data }">
                <span>
                    <i class="el-icon-folder-opened" v-if="data.nodeType == 1"></i>
                    <i class="el-icon-collection" v-else-if="data.nodeType == 100"></i>
                    <i class="el-icon-document" v-else-if="data.nodeType == 101"></i>
                    <i class="el-icon-folder-opened" v-else></i>
                    <span>&nbsp;</span>
                    <span>{{ node.label }}</span>
                </span>
                <span style="padding-left: 10px;" v-if="showMoney && data.nodeType == 100">
                    <span style="font-size: 12px; color: #C00;">￥{{ data.money >= 0 ? data.money : 0 }}</span>
                </span>
            </div>
        </el-tree>
        <div class="nodeMenuBox" ref="nodeMenuBox" style="z-index: 1;">
            <ul class="nodeMenu">
                <li v-show="isShowAddMenu"><el-link :underline="false" @click="toEditorNode('add')">添加子类</el-link></li>
                <li v-show="isShowModifyMenu"><el-link :underline="false" @click="toEditorNode('modify')">修改</el-link></li>
                <li v-show="isShowDelMenu"><el-link :underline="false" @click="toDeleteNode">删除</el-link></li>
            </ul>
        </div>
        <DialogWin ref="nodeEditor" title="编辑" :maxWidth="800" :maxHeight="300">
            <PageBox ref="nodeFormBox">
                <el-form ref="nodeForm" :inline="false" :model="nodeForm" :rules="nodeFormRules" label-width="100px">
                    <div>
                        <el-form-item label="类型" prop="nodeType">
                            <el-select v-model="nodeForm.nodeType" placeholder="请选择" ref="nodeTypeSelect" :disabled="nodeTypeDisabled">
                                <el-option value="1" label="分类" :disabled="disabledFL"></el-option>
                                <el-option value="100" label="科目" :disabled="disabledKM"></el-option>
                                <el-option value="101" label="课程" :disabled="disabledKC"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="名称" prop="label">
                            <el-input v-model="nodeForm.label" placeholder="名称"></el-input>
                        </el-form-item>
                        <el-form-item label="价格" prop="money" v-if="nodeForm.nodeType == 100">
                            <el-input-number v-model="nodeForm.money" :precision="2" :step="1" :max="10000"></el-input-number>
                        </el-form-item>
                    </div>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="saveNode">保存</el-button>
                        <el-button @click="cancelEditorNode">取消</el-button>
                    </div>
                </el-form>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import DialogWin from './DialogWin'
import PageBox from './PageBox'

export default {
    name: 'LessonsNodeTree',
    props: {
        showCheckbox: Boolean,
        showMoney: Boolean,
        openAll: Boolean,
        edit: Boolean,
        endNodeType: Number,
        onClick: Function,
        onCheck: Function,
        onSave: Function,
        onDelete: Function,
        onMove: Function,
        onExpand: Function,
        onCollapse: Function,
        localSaveKey: String,
    },
    data() {
        return {
            tree: [
                { id: 0, nodeLevel: 0, nodeType: -1, label: '全部', children: [] },
            ],
            expandedKeys: [],
            menuNode: {},
            nodeForm: {},
            nodeFormRules: {
                nodeType: [
                    { required: true, message: '请选择类型', trigger: 'blur' },
                ],
                label: [
                    { required: true, message: '输入名称', trigger: 'blur' },
                    { min: 1, message: '长度必须大于1', trigger: 'blur' },
                    { max: 32, message: '长度必须小于32', trigger: 'blur' },
                ],
                money: [
                    { required: true, message: '输入金额', trigger: 'blur' },
                    { type: 'number', message: '金额必须为数字值', trigger: 'blur' },
                    { pattern: /^([0-9]{1,4}([\\.][0-9]+)?)$/, message: '金额必须大于0并小于10000',trigger: 'blur'},
                ],
            },
            nodeTypeDisabled: false,
        }
    },
    computed: {
        isShowAddMenu() {
            //if (this.menuNode?.data?.nodeType == 101) {
            //    return false
            //}
            return true
        },
        isShowModifyMenu() {
            if (this.menuNode?.data?.nodeType == -1) {
                return false
            }
            return true
        },
        isShowDelMenu() {
            if (this.menuNode?.data?.nodeType == -1) {
                return false
            }
            if (this.menuNode?.data?.children?.length > 0) {
                return false
            }
            return true
        },
        disabledFL() {
            if (this.menuNode?.data?.nodeType == -1) {
                return false
            }
            return true
        },
        disabledKM() {
            if (this.menuNode?.data?.nodeType < 100) {
                if (this.menuNode?.data?.children?.length > 0) {
                    return this.menuNode?.data?.children?.filter(it => it.nodeType != 100).length > 0
                } else {
                    return false
                }
            }
            return true
        },
        disabledKC() {
            if (this.menuNode?.data?.nodeType == 100) {
                return false
            }
            if (this.menuNode?.data?.nodeType == 101) {
                return false
            }
            return true
        },
    },
    methods: {
        allowDrop(draggingNode, dropNode, type) {
            if (type == 'inner') {
                return draggingNode.data.nodeType == 101 && (dropNode.data.nodeType == 100 || dropNode.data.nodeType == 101)
            }
            if (type == 'prev' || type == 'next') {
                if (draggingNode.data.nodeType == 100 && draggingNode.data.parentId == dropNode.data.parentId) {
                    return true
                }
                if (draggingNode.data.nodeType == 101 && dropNode.data.nodeType == 101) {
                    return true
                }
                if (draggingNode.data.nodeType == 1 && draggingNode.data.parentId == dropNode.data.parentId) {
                    return true
                }
            }
            return false
        },
        dropDone(draggingNode, dropNode, type) {
            if (this.onMove) {
                this.onMove(draggingNode.data, dropNode.data, type)
            }
        },
        nodeClick(data) {
            if (this.onClick) {
                this.onClick(data)
            }
        },
        nodeCheck(data) {
            if (this.onCheck) {
                this.onCheck(data)
            }
        },
        nodeMenu(event, data, node) {
            if (this.edit) {
                this.menuNode = node
                if (this.menuNode?.data?.id >= 0) {
                    const offsetRect = this.$refs.nodeMenuBox.parentElement.getBoundingClientRect()
                    this.$refs.nodeMenuBox.style.top = event.pageY - offsetRect.top + 'px'
                    this.$refs.nodeMenuBox.style.left = event.pageX - offsetRect.left + 'px'
                    this.$refs.nodeMenuBox.style.display = 'block'
                }
            }
        },
        nodeExpand(data, node) {
            if (this.localSaveKey) {
                let expandNodes = JSON.parse(sessionStorage.getItem(this.localSaveKey)) || []
                expandNodes.push(data.id)
                sessionStorage.setItem(this.localSaveKey, JSON.stringify(expandNodes))
            }
            if (this.onExpand) {
                this.onExpand(data, node)
            }
        },
        nodeCollapse(data, node) {
            if (this.localSaveKey) {
                let expandNodes = JSON.parse(sessionStorage.getItem(this.localSaveKey)) || []
                expandNodes = expandNodes.filter(it => it != data.id)
                sessionStorage.setItem(this.localSaveKey, JSON.stringify(expandNodes))
            }
            if (this.onCollapse) {
                this.onCollapse(data, node)
            }
        },
        toEditorNode(type) {
            this.nodeForm = {
                id: 0,
                nodeType: '',
                label: '',
                money: 0,
            }
            if (type == 'modify') {
                this.nodeForm = JSON.parse(JSON.stringify(this.menuNode.data))
                this.nodeForm.nodeType += ''
            }
            this.nodeTypeDisabled = type != 'add'
            this.$refs.nodeEditor.isShow = true
        },
        toDeleteNode() {
            this.$confirm('此操作将永久删除该行记录, 是否继续?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.deleteNode(this.menuNode?.data)
            }).catch(() => {
            })
        },
        pageClick() {
            this.$refs.nodeMenuBox.style.display = 'none'
        },
        getTree() {
            return this.tree[0].children
        },
        setTree(tree) {
            let data = JSON.parse(JSON.stringify(tree))
            this.forEachNodes(data, node => {
                if (this.endNodeType && node.nodeType == this.endNodeType) {
                    node.children = []
                }
            })
            this.tree[0].children = data
            let expandNodes = JSON.parse(sessionStorage.getItem(this.localSaveKey)) || []
            this.expandedKeys = expandNodes
        },
        getCheckedIds() {
            return this.$refs.elTree.getCheckedKeys()
        },
        setCheckedIds(nodeIds) {
            this.$refs.elTree.setCheckedKeys(nodeIds || [])
        },
        openNodes(nodeIds) {
            this.expandedKeys.splice(0)
            nodeIds = nodeIds || [ 0 ]
            nodeIds.forEach(id => this.expandedKeys.push(id))
        },
        deleteNode(row) {
            if (this.onDelete) {
                this.onDelete(row)
            }
        },
        forEachNodes(nodes, func) {
            nodes.forEach(node => {
                func(node)
                if (node.children && node.children.length > 0) {
                    this.forEachNodes(node.children, func)
                }
            })
        },
        saveNode() {
            this.$refs.nodeForm.validate((valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.nodeForm))
                    let editData = JSON.parse(JSON.stringify(this.menuNode.data))
                    this.$refs.nodeEditor.isShow = false
                    this.$refs.nodeForm.resetFields()
                    if (this.onSave) {
                        this.onSave(data, editData)
                    }
                } else {
                    return false
                }
            })
        },
        cancelEditorNode() {
            this.$refs.nodeEditor.isShow = false
            this.$refs.nodeForm.resetFields()
        },
    },
    components: {
        DialogWin,
        PageBox,
    },
    mounted() {
        window.addEventListener('click', this.pageClick)
    },
    beforeDestroy() {
        window.removeEventListener('click', this.pageClick)
    }
}
</script>

<style scoped>
.el-tree {
    background-color: unset;
}
.rootDiv {
    position: relative;
}
.nodeMenuBox {
    display: none;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #fff;
    border: solid 1px #ccc;
}
.nodeMenu {
    margin: 0;
    padding: 4px 0;
}
.nodeMenu>li {
    list-style: none;
    line-height: 26px;
    height: 26px;
    padding: 0 15px;
    text-align: center;
}
.nodeMenu>li:hover {
    background-color: rgba(0,0,0,0.1);
}
</style>
