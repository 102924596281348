<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                ref="videoFileTable"
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :maxEditWinWidth="800"
                :maxEditWinHeight="320"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :toAddFun="toAdd"
                :params="dataTableParams"
            />
        </PageBox>
        <DialogWin ref="uploadWin" :maxWidth="500" :maxHeight="300" :onClose="onCloseUpload">
            <PageBox>
                <div style="text-align: center;">
                    <el-upload
                        ref="uploader"
                        class="upload-demo"
                        drag
                        action="/oll-manager/user/video/upload"
                        :on-success="onUploadSuccess"
                        multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传zip文件，且不超过500M</div>
                    </el-upload>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { /*pmsConfig,*/ userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'
import DialogWin from '../components/DialogWin'

export default {
    name: 'VideoPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/video/list',
                titles: [
                    { prop: 'videoTitle', label: '文件名' },
                    { prop: 'videoTime', label: '总时长', width: 200, filter: (value) => Math.round(value) + '秒' },
                    { prop: 'videoClassId', label: '分组', width: 200, filter: (value) => this.dataTableParams.editVideoClass.filter(it => it.value == value).pop()?.label || '未分类' },
                    { prop: 'videoState', label: '视频状态', width: 80, mapping: { '0': '新建', '1': '处理中', '2': '完成', '3': '错误' } },
                    { prop: 'createDate', label: '上传时间', width: 160 },
                ],
                delUrl: '/user/video/del',
                updateUrl: '/user/video/update',
                actions: [
                   // { label: '', width: 66, func: this.modifyPms },
                ],
                searchFormLines: [
                    { prop: 'videoTitle', label: '文件名', type: 'text' },
                    { prop: 'videoState', label: '视频状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: '0', label: '新建' },
                        { value: '1', label: '处理中' },
                        { value: '2', label: '完成' },
                        { value: '3', label: '错误' },
                    ] },
                    { prop: 'videoClassId', label: '视频分类', type: 'select', options: 'searchVideoClass' },
                    { prop: 'createDateBegin', label: '开始时间', type: 'date' },
                    { prop: 'createDateEnd', label: '结束时间', type: 'date' },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'videoTitle', label: '文件名', type: 'text', rule: { required: true, min: 1, max: 120 } },
                    { prop: 'videoClassId', label: '视频分类', type: 'select', options: 'editVideoClass', rule: { required: true } },
                ],
            },
            dataTableParams: {
                searchVideoClass: [],
                editVideoClass: [],
            },
            addForm: {
                videoClassId: 0,
            },
        }
    },
    methods: {
        toAdd() {
            this.$refs.uploader?.clearFiles()
            this.$refs.uploadWin.isShow = true
        },
        onUploadSuccess(result) {
            this.onCloseUpload()
            if (result.code == 1000) {
                console.log(result.msg)
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
        },
        onCloseUpload() {
            this.$refs.videoFileTable.refreshData()
            this.$refs.uploader?.clearFiles()
            this.$refs.uploadWin.isShow = false
        },
        loadVideoClassItems() {
            this.$http.get('/user/vc/classItems').then(result => {
                if (result.code == '1000') {
                    this.dataTableParams.editVideoClass.splice(0, this.dataTableParams.editVideoClass.length)
                    this.dataTableParams.editVideoClass.push({ value: 0, label: '未分类' })
                    this.dataTableParams.searchVideoClass.splice(0, this.dataTableParams.searchVideoClass.length)
                    this.dataTableParams.searchVideoClass.push({ value: '', label: '' })
                    this.dataTableParams.searchVideoClass.push({ value: 0, label: '未分类' })
                    result.data.items.forEach(it => {
                        this.dataTableParams.editVideoClass.push({ value: it.id, label: it.videoClassName })
                        this.dataTableParams.searchVideoClass.push({ value: it.id, label: it.videoClassName })
                    })
                }
            })
        },
    },
    components: {
        PageBox,
        DataTable,
        DialogWin,
    },
    mixins: [ userPms ],
    mounted() {
        this.$refs.videoFileTable.showAdd = true
        this.loadVideoClassItems()
    }
}
</script>

<style scoped>
</style>
