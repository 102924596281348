import { render, staticRenderFns } from "./SplitPane.vue?vue&type=template&id=4dbf4912&scoped=true&"
import script from "./SplitPane.vue?vue&type=script&lang=ts&"
export * from "./SplitPane.vue?vue&type=script&lang=ts&"
import style0 from "./SplitPane.vue?vue&type=style&index=0&id=4dbf4912&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dbf4912",
  null
  
)

export default component.exports