<template>
    <div style="width: 100%; height: 100%; padding: 15px; box-sizing: border-box;"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <slot />
    </div>
</template>

<script lang="ts">
export default {
    name: 'PageBox',
    props: {
        defLoading: Boolean,
    },
    data() {
        return {
            loading: false,
        }
    },
    mounted() {
        this.loading = this.defLoading === true
    }
}
</script>

<style scoped>
</style>
