import { render, staticRenderFns } from "./ConfigPage.vue?vue&type=template&id=1eed2d7b&scoped=true&"
import script from "./ConfigPage.vue?vue&type=script&lang=ts&"
export * from "./ConfigPage.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigPage.vue?vue&type=style&index=0&id=1eed2d7b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eed2d7b",
  null
  
)

export default component.exports