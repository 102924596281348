import { render, staticRenderFns } from "./VideoClassPage.vue?vue&type=template&id=4bbc15f4&scoped=true&"
import script from "./VideoClassPage.vue?vue&type=script&lang=ts&"
export * from "./VideoClassPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbc15f4",
  null
  
)

export default component.exports