<template>
    <div>
        <div style="width: 300px; margin: 0 auto; text-align: center;" :style="{ paddingTop: formTop + 'px' }">
            <form>
                <div style="padding: 10px 0;">
                    <el-input v-model="loginid" placeholder="请输入账号" autocomplete="off"></el-input>
                </div>
                <div style="padding: 10px 0;">
                    <el-input v-model="password" placeholder="请输入密码" autocomplete="off" show-password></el-input>
                </div>
                <div style="padding: 10px 0;">
                    <el-button type="primary" @click.prevent="doLogin">登录</el-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import md5 from 'js-md5'

export default {
    name: 'LoginPage',
    data() {
        return {
            loginid: '',
            password: '',
            innerHeight: 0,
            formTop: 0,
        }
    },
    methods: {
        resize() {
            this.innerHeight = window.innerHeight
            this.formTop = parseInt((innerHeight - 180) / 2)
            this.formTop = this.formTop >= 0 ? this.formTop : 0
        },
        doLogin() {
            this.$http.post('/doLogin', { loginid: this.loginid, password: md5(this.password) })
                .then(result => {
                    if (result.code == 1000) {
                        this.$bus.$emit('loginDone', true)
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'warning'
                        })
                    }
                })
        },
    },
    mounted() {
        window.addEventListener('resize', this.resize)
        this.resize()
    }
}
</script>

<style scoped>
</style>
