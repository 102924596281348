<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <div style="height: 100%; border: solid 1px #DDD;">
                <SplitPane :leftWidth="600" style="height: 100%;">
                    <div slot="one" style="width: 100%; height: 100%; overflow: auto;">
                        <div style="padding: 10px; width: 100%; height: 100%; box-sizing: border-box;">
                            <DataTable
                                ref="paperTable"
                                :ps="100"
                                :pn="1"
                                :searchFieldOneLine="true"
                                :showIndex="dataTable.showIndex"
                                :titles="dataTable.titles"
                                :dataUrl="dataTable.dataUrl"
                                :searchFormLines="dataTable.searchFormLines"
                                :editFormLines="dataTable.editFormLines"
                                :pageBar="dataTable.pageBar"
                                :actions="dataTable.actions"
                                :addUrl="dataTable.addUrl"
                                :updateUrl="dataTable.updateUrl"
                                :delUrl="dataTable.delUrl"
                                :activeUrl="dataTable.activeUrl"
                                :maxEditWinWidth="800"
                                :maxEditWinHeight="350"
                                :loadListen="dataTableLoadListen"
                                :params="dataTableParams"
                                :topButtons="dataTable.topButtons"
                            />
                        </div>
                    </div>
                    <div slot="two" style="width: 100%; height: 100%; overflow: auto;">
                        <PageBox ref="questionPageBox">
                            <div v-show="editPaperId > 0">
                                <QuestionTable ref="questionsTable" :loaded="questionsLoaded" />
                            </div>
                        </PageBox>
                    </div>
                </SplitPane>
            </div>
        </PageBox>
        <DialogWin ref="uploadWin" :maxWidth="500" :maxHeight="300" :onClose="onCloseUpload">
            <PageBox>
                <div style="text-align: center;">
                    <el-upload
                        ref="uploader"
                        class="upload-demo"
                        drag
                        action="/oll-manager/user/paper/importPaper"
                        :on-success="onUploadSuccess"
                        multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传zip文件，且不超过500M</div>
                    </el-upload>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import SplitPane from '../components/SplitPane'
import DataTable from '../components/DataTable'
import QuestionTable from '../components/QuestionTable'
import DialogWin from '../components/DialogWin'

export default {
    name: 'PaperPage',
    props: {
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/paper/list',
                titles: [
                    { prop: 'paperTitle', label: '试卷标题', showOverflowTooltip: true },
                    { prop: 'paperType', label: '试卷类型', width: 80, mapping: { 0: '其他', 1: '习题', 2: '模拟考', 3: '真题' } },
                    { prop: 'paperClassId', label: '试卷分类', width: 80, filter: (value) => this.dataTableParams.editPaperClass.filter(it => it.value == value).pop()?.label || '未分类' },
                    { prop: 'activeStatus', label: '启用状态', width: 80, mapping: { 'Y': '启用', 'N': '禁用' } },
                ],
                addUrlValue: '/user/paper/add',
                updateUrlValue: '/user/paper/update',
                delUrlValue: '/user/paper/del',
                topButtons: [
                    { label: '导入', action: this.toImport },
                    { label: '下载模板', action: this.downloadExcl },
                ],
                actionsConfig: [
                    { label: '题目', width: 66, func: this.editQuestions, pms: pmsConfig.PMS_QUESTIONS_MANAGER },
                ],
                actions: [],
                searchFormLines: [
                    { prop: 'paperTitle', label: '试卷标题', type: 'text' },
                    { prop: 'paperType', label: '试卷类型', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 1, label: '习题' },
                        { value: 2, label: '模拟考' },
                        { value: 3, label: '真题' }
                    ] },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                    { prop: 'paperClassId', label: '试卷分类', type: 'select', options: 'searchPaperClass' },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'paperTitle', label: '试卷标题', type: 'text', rule: { required: true, min: 1, max: 32 } },
                    { prop: 'paperType', label: '试卷类型', type: 'select', rule: { required: true }, options: [
                        { value: 1, label: '习题' },
                        { value: 2, label: '模拟考' },
                        { value: 3, label: '真题' }
                    ] },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', rule: { required: true }, options: [
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                    { prop: 'paperClassId', label: '试卷分类', type: 'select', options: 'editPaperClass', rule: { required: true } },
                ],
            },
            editPaperId: 0,
            editPaperType: -1,
            dataTableParams: {
                searchPaperClass: [],
                editPaperClass: [],
            },
        }
    },
    computed: {
        endNodeType() {
            if (this.editPaperType == 1) {
                return 0
            }
            if (this.editPaperType != 1) {
                return 100
            }
            return 0
        },
    },
    methods: {
        onUpdateUserPms() {
            this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_QUESTIONS_MANAGER) ? this.dataTable.addUrlValue : ''
            this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_QUESTIONS_MANAGER) ? this.dataTable.updateUrlValue : ''
            this.dataTable.delUrl = this.hasPms(pmsConfig.PMS_QUESTIONS_MANAGER) ? this.dataTable.delUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
            this.editPaperId = 0
        },
        editQuestions(row) {
            this.editPaperId = row.id
            this.$refs.questionPageBox.loading = true
            this.$refs.questionsTable.loadData(row.id)
        },
        questionsLoaded() {
            if (this.$refs.questionPageBox) {
                this.$refs.questionPageBox.loading = false
            }
        },
        loadPaperClassItems() {
            this.$http.get('/user/pc/classItems').then(result => {
                if (result.code == '1000') {
                    this.dataTableParams.editPaperClass.splice(0, this.dataTableParams.editPaperClass.length)
                    this.dataTableParams.editPaperClass.push({ value: 0, label: '未分类' })
                    this.dataTableParams.searchPaperClass.splice(0, this.dataTableParams.searchPaperClass.length)
                    this.dataTableParams.searchPaperClass.push({ value: '', label: '' })
                    this.dataTableParams.searchPaperClass.push({ value: 0, label: '未分类' })
                    result.data.items.forEach(it => {
                        this.dataTableParams.editPaperClass.push({ value: it.id, label: it.paperClassName })
                        this.dataTableParams.searchPaperClass.push({ value: it.id, label: it.paperClassName })
                    })
                }
            })
        },
        toImport() {
            this.$refs.uploadWin.isShow = true
        },
        onUploadSuccess(result) {
            this.onCloseUpload()
            if (result.code == 1000) {
                console.log(result.msg)
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
        },
        onCloseUpload() {
            this.$refs.paperTable.refreshData()
            this.$refs.uploader?.clearFiles()
            this.$refs.uploadWin.isShow = false
        },
        downloadExcl() {
            window.open('/oll-manager/user/paper/downloadExcl')
        },
    },
    components: {
        PageBox,
        SplitPane,
        DataTable,
        QuestionTable,
        DialogWin,
    },
    mixins: [ userPms ],
    mounted() {
        this.loadPaperClassItems()
    }
}
</script>

<style scoped>
</style>
