<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :activeUrl="dataTable.activeUrl"
                :maxEditWinWidth="800"
                :maxEditWinHeight="480"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'

export default {
    name: 'VersionRecordPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/version/list',
                titles: [
                    { prop: 'platformName', label: '平台类型', mapping: { 'iOS': '苹果', 'Android': '安卓' } },
                    { prop: 'versionLabel', label: '版本号' },
                    { prop: 'versionDescription', label: '版本说明' },
                    { prop: 'mustUpdate', label: '是否必须更新', mapping: { 'Y': '是', 'N': '否' } },
                    { prop: 'activeStatus', label: '启用状态', mapping: { 'Y': '启用', 'N': '禁用' } },
                ],
                addUrlValue: '/user/version/add',
                updateUrlValue: '/user/version/update',
                delUrlValue: '/user/version/del',
                activeUrlValue: '/user/version/active',
                actionsConfig: [],
                actions: [],
                searchFormLines: [
                    { prop: 'platformName', label: '平台类型', type: 'select', options: [
                        { value: 'iOS', label: '苹果' },
                        { value: 'Android', label: '安卓' }
                    ] },
                    { prop: 'versionLabel', label: '版本号', type: 'text' },
                    { prop: 'versionDescription', label: '版本说明', type: 'text' },
                    { prop: 'mustUpdate', label: '必须更新', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '是' },
                        { value: 'N', label: '否' }
                    ] },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'platformName', label: '平台类型', type: 'select', rule: { required: true }, options: [
                        { value: 'iOS', label: '苹果' },
                        { value: 'Android', label: '安卓' }
                    ] },
                    { prop: 'versionLabel', label: '版本号', type: 'text', rule: { required: true, min: 5, max: 32 } },
                    { prop: 'versionDescription', label: '版本说明', type: 'textarea', rule: { required: true, min: 0, max: 500 } },
                    { prop: 'downloadUrl', label: '下载链接', type: 'textarea', rule: { required: true, min: 0, max: 500 } },
                    { prop: 'mustUpdate', label: '必须更新', type: 'select', rule: { required: true }, options: [
                        { value: 'Y', label: '是' },
                        { value: 'N', label: '否' }
                    ] },
                    { prop: 'activeStatus', label: '启用状态', type: 'select', rule: { required: true }, options: [
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                ],
            },
        }
    },
    methods: {
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        onUpdateUserPms() {
            this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_USER_GROUP_ADD) ? this.dataTable.addUrlValue : ''
            this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_USER_GROUP_UPDATE) ? this.dataTable.updateUrlValue : ''
            this.dataTable.delUrl = this.hasPms(pmsConfig.PMS_USER_GROUP_DELETE) ? this.dataTable.delUrlValue : ''
            this.dataTable.activeUrl = this.hasPms(pmsConfig.PMS_USER_GROUP_ACTIVE) ? this.dataTable.activeUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
        },
    },
    components: {
        PageBox,
        DataTable,
    },
    mixins: [ userPms ],
    mounted() {
    }
}
</script>

<style scoped>
/deep/ .el-checkbox.is-disabled {
    display: none;
}
</style>
