<template>
    <PageBox ref="questionPageBox" v-show="isShow">
        <div style="text-align: center;" v-show="!hideGroupTitle">
            <h2>{{ paperTitle }}</h2>
        </div>
        <div>
            <div style="text-align: right; padding: 10px 0;">
                <el-button type="primary" size="mini" @click="toEditQuestionGroup()">添加分组</el-button>
            </div>
            <div>
                <el-collapse accordion>
                    <el-collapse-item v-for="questionGroup in questionGroups" :key="questionGroup.id">
                        <template slot="title">
                            <el-tooltip class="item" effect="light" content="删除" placement="top">
                                <i class="el-icon-circle-close" style="color: red; font-size: 16px;" @click.stop="delQuestionGroup(questionGroup)"></i>
                            </el-tooltip>
                            <span style="margin-left: 5px;">{{ questionGroup.groupName }}</span>
                        </template>
                        <div style="text-align: right;">
                            <el-button type="primary" size="mini" @click="toEditQuestion(questionGroup)">添加题目</el-button>
                        </div>

                        <el-table :data="questionGroup.questions" style="width: 100%">
                            <el-table-column type="index" label="序号" width="50" fixed="left" />
                            <el-table-column prop="questionType" label="题型" width="100">
                                <template slot-scope="scope">
                                    {{ questionTypeMapping(scope.row.questionSubType) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="questionText" label="题目" :show-overflow-tooltip="true">
                                <template slot-scope="scope">
                                    <span>{{ htmlToText(scope.row.questionText) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="120" fixed="right">
                                <template slot-scope="scope">
                                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="toEditQuestion(questionGroup, scope.row)"></el-button>
                                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="doDelQuestion(scope.row)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <DialogWin ref="questionGroupWin" title="编辑题目分组" width="800px" height="400px">
            <div style="padding: 20px;">
                <el-form ref="questionGroupFrom" label-width="80px">
                    <el-form-item label="题型">
                        <el-select v-model="questionGroupFrom.questionType">
                            <el-option v-for="option in questionTypeOptions" :key="option.value" :value="option.value" :label="option.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="doQuestionGroup()">确定</el-button>
                        <el-button @click="$refs.questionGroupWin.isShow = false">取消</el-button>
                    </div>
                </el-form>
            </div>
        </DialogWin>
        <QuestionEditView ref="simpleQuestionEditView" :onSaved="onSavedQuestion" />
        <MultipleQuestionEditView ref="multipleQuestionEditView" :onSaved="onSavedQuestion" :onDeleted="onDeletedQuestion" />
    </PageBox>
</template>

<script lang="ts">
import PageBox from '../components/PageBox'
import DialogWin from '../components/DialogWin'
import QuestionEditView from '../components/QuestionEditView'
import MultipleQuestionEditView from '../components/MultipleQuestionEditView'

export default {
    name: 'QuestionTable',
    props: {
        loaded: Function,
        hideGroupTitle: Boolean,
    },
    data() {
        return {
            paperId: 0,
            paperTitle: '',
            questionGroups: [],
            questionGroupFrom: {},
            questionTypeOptions: [
            /*
                { value: 1, label: '填空' },
                { value: 2, label: '判断' },
                { value: 3, label: '单选' },
                { value: 4, label: '多选' },
                { value: 5, label: '不定项选择' },
                { value: 1101, label: '阅读理解', hasChildren: true },
            */
            ],
            editingGroup: {},
            editingRow: {},
        }
    },
    computed: {
        isShow() {
            return this.paperId && this.paperId > 0
        },
    },
    methods: {
        reloadData(callback) {
            this.loadData(this.paperId, callback)
        },
        loadData(paperId, callback) {
            this.paperId = paperId
            this.paperTitle = ''
            this.questionGroups.splice(0)
            this.$http.get('/user/questions/getFullPaper', { paperId }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '加载成功!'
                    })
                    this.paperTitle = result.data.paper.paperTitle
                    result.data.paper.questionGroups.forEach(questionGroup => this.questionGroups.push(questionGroup))
                    if (callback) {
                        callback(this.questionGroups)
                    }
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                if (this.loaded) {
                    this.loaded()
                }
            })
        },
        questionTypeMapping(questionType) {
            let targetArr = this.questionTypeOptions.filter(option => option.value == questionType)
            if (targetArr.length > 0) {
                return targetArr[0].label
            }
            return questionType
        },
        toEditQuestionGroup() {
            this.$refs.questionGroupWin.isShow = true
        },
        delQuestionGroup(questionGroup) {
            this.$confirm('你确定要删除这个分组吗?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.$http.del('/user/questionGroup/delGroup', questionGroup.id).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        })
                        this.reloadData()
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
            })
        },
        doQuestionGroup() {
            let data = {
                paperId: this.paperId,
                questionType: this.questionGroupFrom.questionType,
            }
            this.$http.post('/user/questionGroup/addGroup', data).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                    this.reloadData()
                    this.$refs.questionGroupWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        onSavedQuestion() {
            this.reloadData((questionGroups) => {
                if (this.$refs.multipleQuestionEditView.isOpen()) {
                    let newGroup = questionGroups.filter(it => it.id == this.editingGroup?.id)[0]
                    let newRow = newGroup.questions.filter(it => it.id == this.editingRow?.id)[0]
                    this.editingGroup = newGroup
                    this.editingRow = newRow
                    this.$refs.multipleQuestionEditView.openWin((view) => {
                        view.setQuestionData(this.editingGroup, this.editingRow)
                    })
                }
            })
        },
        onDeletedQuestion () {
            this.reloadData((questionGroups) => {
                if (this.$refs.multipleQuestionEditView.isOpen()) {
                    let newGroup = questionGroups.filter(it => it.id == this.editingGroup?.id)[0]
                    let newRow = newGroup.questions.filter(it => it.id == this.editingRow?.id)[0]
                    this.editingGroup = newGroup
                    this.editingRow = newRow
                    this.$refs.multipleQuestionEditView.openWin((view) => {
                        view.setQuestionData(this.editingGroup, this.editingRow)
                    })
                }
            })
        },
        doDelQuestion(row) {
            this.$confirm('此操作将永久删除该行记录, 是否继续?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.$refs.questionPageBox.loading = true
                this.$http.del('/user/questions/del', row.id).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        })
                        this.reloadData()
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                    this.$refs.questionPageBox.loading = false
                })
            }).catch(() => {
            })
        },
        toEditQuestion(questionGroup, row) {
            this.editingGroup = questionGroup
            this.editingRow = row
            if (!questionGroup.hasChildren) {
                this.$refs.simpleQuestionEditView.openWin((view) => {
                    view.setQuestionData(questionGroup, row)
                })
            } else {
                this.$refs.multipleQuestionEditView.openWin((view) => {
                    view.setQuestionData(questionGroup, row)
                })
            }
        },
        toEditParentQuestion(questionGroup, parentRow, row) {
            console.log(questionGroup, parentRow, row)
            /*
            this.$refs.parentQuestionWin.isShow = true
            //this.toEditQuestion(questionGroup, row)
            this.$http.post('/user/questionGroup/addGroup', data).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.reloadData()
                    this.$refs.questionGroupWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
            */
        },
        htmlToText(questionText) {
            let maxLength = 50
            let div = document.createElement('div')
            div.innerHTML = questionText
            let text = div.innerText
            text = text.length <= maxLength ? text : text.substring(0, maxLength) + '...'
            return text
        },
    },
    components: {
        PageBox,
        DialogWin,
        QuestionEditView,
        MultipleQuestionEditView,
    },
    mounted() {
        this.$http.get('/user/questionType/getQuestionTypes').then((result) => {
            if (result.code == 1000) {
                this.questionTypeOptions.splice(0)
                result.data.questionTypes?.forEach(questionType => {
                    this.questionTypeOptions.push(questionType)
                })
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
        })
    }
}
</script>

<style scoped>
.answerBox {
    padding: 0 20px;
}
.addOptionBtnBox {
    padding-top: 10px;
}
</style>

<style>
.el-radio {
    width: 100%;
}
</style>
