<template>
    <div ref="editorBox" style="line-height: 20px; z-index: -1;">
    </div>
</template>

<script lang="ts">
export default {
    name: 'UEditor',
    props: {
        w: String,
        h: String,
        onReady: Function,
    },
    data() {
        return {
            id: '',
            building: false,
            inited: false,
            ue: null,
            width: '100%',
            height: '',
        }
    },
    methods: {
        doInit() {
            if (this.inited || this.building) {
                return
            }
            this.doDestroy()
            this.building = true
            this.$refs.editorBox.innerHTML = '<div id="' + this.id + '" :style="width: ' + this.width + '; height: ' + this.height + ';"></div>'
            this.ue = window.UE.getEditor(this.id, { zIndex: 1992 })
            this.ue.ready(() => {
                this.building = false
                this.inited = true
                if (this.onReady) {
                    this.onReady(this)
                }
            })
        },
        getContent() {
            if (this.inited) {
                return this.ue.getContent()
            }
        },
        setContent(content) {
            /*
            if (this.inited && content !== undefined) {
                this.ue.setContent(content)
            }
            */
            if (this.inited) {
                this.ue.setContent(content || '')
            }
        },
        doDestroy() {
            this.ue?.destroy()
            this.ue = null
            this.$refs.editorBox.innerHTML = ''
            this.building = false
            this.inited = false
        },
    },
    created() {
        this.id = 'UEditor-' + Date.now() + '-' + parseInt(Math.random() * 10000)
    },
    mounted() {
        if (this.w) { this.width = this.w }
        if (this.h) { this.height = this.h }
        this.doInit()
    }
}
</script>

<style scoped>
</style>
