<template>
    <div style="height: 100%;">
        <SplitPane style="height: 100%;" :leftWidth="400">
            <div slot="one" style="height: 100%;">
                <PageBox ref="treePageBox">
                    <LessonsNodeTree
                        :showMoney="true"
                        :edit="true"
                        :onClick="onClickTree"
                        :onDelete="onDeleteTree"
                        :onSave="onSaveTree"
                        :onMove="onMoveTree"
                        localSaveKey="LessonsPageExpandTrees"
                        ref="treeNode" />
                </PageBox>
            </div>
            <div slot="two" style="height: 100%;">
                <PageBox ref="contentBody">
                    <div v-show="isShowForm">
                        <el-form ref="form" :model="lessonsForm" label-width="80px">
                            <el-form-item label="课程名">
                                <span>{{ showRow.label }}</span>
                            </el-form-item>
                            <el-form-item label="课程图标" v-if="isSubject">
                                <img width="80" height="80" :src="lessonsForm.iconUrl" />
                                <el-button type="primary" style="vertical-align: top; margin-left: 20px;" @click="toSetSubjectIconWin">修改</el-button>
                            </el-form-item>
                            <el-form-item label="视频文件" v-if="!isSubject">
                                <input type="hidden" v-model="lessonsForm.videoCode" />
                                <div style="display: flex;">
                                    <el-input type="text" :value="lessonsForm.videoFileName" disabled></el-input>
                                    <el-button type="primary" style="margin-left: 10px;" @click="toChoseVideo">选择</el-button>
                                    <el-button style="margin-left: 10px;" @click="clearVideo">清空</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item label="课程简介" v-if="!isSubject">
                                <div>
                                    <UEditor ref="introductionEditor" :onReady="introductionEditorReady" />
                                </div>
                            </el-form-item>
                        </el-form>
                        <el-collapse accordion>
                            <el-collapse-item title="课件" v-if="!isSubject" name="1">
                                <div style="margin-left: 80px;">
                                    <DataTable
                                        ref="fileTable"
                                        :ps="5"
                                        :pn="1"
                                        :searchFieldOneLine="true"
                                        :showIndex="lessonDataTable.showIndex"
                                        :titles="lessonDataTable.titles"
                                        :dataUrl="lessonDataTable.dataUrl"
                                        :pageBar="lessonDataTable.pageBar"
                                        :delUrl="lessonDataTable.delUrl"
                                        :actions="lessonDataTable.actions"
                                        :toAddFun="toAddFile"
                                        :baseSearchParams="lessonNodeData"
                                    />
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="试卷/习题" nanme="2">
                                <div style="margin-left: 80px;">
                                    <DataTable
                                        ref="paperTable"
                                        :ps="10"
                                        :pn="1"
                                        :searchFieldOneLine="true"
                                        :showIndex="paperDataTable.showIndex"
                                        :titles="paperDataTable.titles"
                                        :dataUrl="paperDataTable.dataUrl"
                                        :pageBar="paperDataTable.pageBar"
                                        :delUrl="paperDataTable.delUrl"
                                        :actions="paperDataTable.actions"
                                        :toAddFun="toAddPaper"
                                        :baseSearchParams="lessonNodeData"
                                    />
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <div style="text-align: center; padding: 20px;">
                            <el-button type="primary" @click="saveLessonsInfo">保存</el-button>
                            <el-button @click="closeLessonsInfo">关闭</el-button>
                        </div>
                    </div>
                </PageBox>
            </div>
        </SplitPane>
        <DialogWin ref="addFileWin" title="添加课件" :maxWidth="500" :maxHeight="300">
            <PageBox ref="addFilePageBox">
                <div style="text-align: center;">
                    <el-upload
                        ref="lessonFileUploader"
                        class="upload-demo"
                        drag action="/oll-manager/user/lessons/uploadFile"
                        multiple
                        :limit="1"
                        :data="lessonNodeData"
                        :before-upload="onUploadBefore"
                        :on-success="onUploadFinished">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传 pdf,doc,ppt,xls,docx,pptx,xlsx 文件，且不超过20M</div>
                    </el-upload>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="subjectIconWin" title="设置课程图标" :maxWidth="500" :maxHeight="300">
            <PageBox ref="setSubjectIconPageBox">
                <div style="text-align: center;">
                    <el-upload
                        ref="subjectIconFileUploader"
                        class="upload-demo"
                        drag action="/oll-manager/user/lessons/uploadSubjectIcon"
                        multiple
                        :limit="1"
                        :data="lessonNodeData"
                        :before-upload="onUploadIconBefore"
                        :on-success="onUploadIconFinished">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">上传 jpg,png 文件，且不超过1M</div>
                    </el-upload>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="addPaperWin" title="添加试卷" :onOpen="onOpenAddPaperWin">
            <PageBox ref="addPaperPageBox">
                <DataTable
                    ref="addPaperTable"
                    :ps="5"
                    :pn="1"
                    :searchFieldOneLine="true"
                    :showIndex="paperInfoDataTable.showIndex"
                    :titles="paperInfoDataTable.titles"
                    :dataUrl="paperInfoDataTable.dataUrl"
                    :searchFormLines="paperInfoDataTable.searchFormLines"
                    :pageBar="paperInfoDataTable.pageBar"
                    :actions="paperInfoDataTable.actions"
                    :baseSearchParams="{ activeStatus: 'Y' }"
                    :params="paperInfoDataTableParams"
                />
                <div style="text-align: center; padding: 20px 0;">
                    <el-button @click="closeAddPaperWin">关闭</el-button>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="choseVideoWin" :onOpen="onOpenChoseVideoWin">
            <PageBox ref="choseVideoPageBox">
                <DataTable
                    ref="choseVideoTable"
                    :ps="5"
                    :pn="1"
                    :searchFieldOneLine="true"
                    :showIndex="videoInfoDataTable.showIndex"
                    :titles="videoInfoDataTable.titles"
                    :dataUrl="videoInfoDataTable.dataUrl"
                    :searchFormLines="videoInfoDataTable.searchFormLines"
                    :pageBar="videoInfoDataTable.pageBar"
                    :actions="videoInfoDataTable.actions"
                    :baseSearchParams="{ videoState: '2' }"
                    :params="videoInfoDataTableParams"
                />
                <div style="text-align: center; padding: 20px 0;">
                    <el-button @click="closeChoseVideoWin">关闭</el-button>
                </div>
            </PageBox>
        </DialogWin>
        <DialogWin ref="modifyFileOrderNumWin" :maxWidth="400" :maxHeight="200">
            <PageBox ref="modifyFileOrderNumPageBox">
                <el-form :inline="false" :model="fileOrderNumForm" label-width="80px">
                    <el-form-item label="排序">
                        <el-input v-model="fileOrderNumForm.orderNum"></el-input>
                    </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="saveFileOrderNum">保存</el-button>
                        <el-button @click="cancelModifyFileOrderNum">取消</el-button>
                    </div>
                </el-form>
            </PageBox>
        </DialogWin>
        <DialogWin ref="modifyPaperOrderNumWin" :maxWidth="400" :maxHeight="200">
            <PageBox ref="modifyPaperOrderNumPageBox">
                <el-form :inline="false" :model="paperOrderNumForm" label-width="80px">
                    <el-form-item label="排序">
                        <el-input v-model="paperOrderNumForm.orderNum"></el-input>
                    </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="savePaperOrderNum">保存</el-button>
                        <el-button @click="cancelModifyPaperOrderNum">取消</el-button>
                    </div>
                </el-form>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { /*pmsConfig,*/ userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import SplitPane from '../components/SplitPane'
import LessonsNodeTree from '../components/LessonsNodeTree'
import DataTable from '../components/DataTable'
import DialogWin from '../components/DialogWin'
import UEditor from '../components/UEditor'

export default {
    name: 'LessonsPage',
    data() {
        return {
            isShowForm: false,
            isSubject: false,
            showRow: {},
            lessonsForm: {
                introduction: '',
            },
            lessonDataTable: {
                showIndex: true,
                dataUrl: '/user/lessons/getNodeFiles',
                titles: [
                    { prop: 'fileCode', label: '文件标识' },
                    { prop: 'fileFullName', label: '文件名' },
                    { prop: 'orderNum', label: '排序' },
                ],
                actions: [
                    { label: '排序', width: 66, func: this.modifyFileOrderNum },
                ],
                pageBar: true,
                delUrl: '/user/lessons/delNodeFile',
            },
            paperDataTable: {
                showIndex: true,
                dataUrl: '/user/lessons/getNodePapers',
                titles: [
                    { prop: 'paperId', label: '试卷ID', width: 80 },
                    { prop: 'paperType', label: '试卷类型', width: 80, mapping: { 0: '其他', 1: '习题', 2: '模拟考', 3: '真题' } },
                    { prop: 'paperTitle', label: '试卷名称' },
                    { prop: 'paperClassId', label: '试卷分类', width: 80, filter: (value) => this.paperInfoDataTableParams.searchPaperClass.filter(it => it.value == value).pop()?.label || '未分类' },
                    { prop: 'orderNum', label: '排序' },
                ],
                actions: [
                    { label: '排序', width: 66, func: this.modifyPaperOrderNum },
                ],
                pageBar: true,
                delUrl: '/user/lessons/delNodePaper',
            },
            fileOrderNumForm: {
                orderNum: 0,
            },
            paperOrderNumForm: {
                orderNum: 0,
            },
            lessonNodeData: {
                lessonNodeId: 0,
            },
            paperInfoDataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/paper/list',
                titles: [
                    { prop: 'paperTitle', label: '试卷标题', showOverflowTooltip: true },
                    { prop: 'paperType', label: '试卷类型', width: 80, mapping: { 0: '其他', 1: '习题', 2: '模拟考', 3: '真题' } },
                    { prop: 'activeStatus', label: '启用状态', width: 80, mapping: { 'Y': '启用', 'N': '禁用' } },
                    { prop: 'paperClassId', label: '试卷分类', width: 80, filter: (value) => this.paperInfoDataTableParams.searchPaperClass.filter(it => it.value == value).pop()?.label || '未分类' },
                ],
                searchFormLines: [
                    { prop: 'paperTitle', label: '试卷标题', type: 'text' },
                    { prop: 'paperType', label: '试卷类型', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 1, label: '习题' },
                        { value: 2, label: '模拟考' },
                        { value: 3, label: '真题' }
                    ] },
                    { prop: 'paperClassId', label: '试卷分类', type: 'select', options: 'searchPaperClass' },
                ],
                actions: [
                    { label: '选择', width: 66, func: this.chosePaper, type: 'primary', plain: false },
                ],
            },
            videoInfoDataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/video/list',
                titles: [
                    { prop: 'videoTitle', label: '视频名', showOverflowTooltip: true },
                ],
                searchFormLines: [
                    { prop: 'videoTitle', label: '视频名', type: 'text' },
                    { prop: 'videoClassId', label: '视频分类', type: 'select', options: 'searchVideoClass' },
                ],
                actions: [
                    { label: '选择', width: 66, func: this.choseVideo, type: 'primary', plain: false },
                ],
            },
            videoInfoDataTableParams: {
                searchVideoClass: [],
            },
            paperInfoDataTableParams: {
                searchPaperClass: [],
            },
        }
    },
    methods: {
        onClickTree(row) {
            this.isShowForm = false
            if (row.nodeType == 101 || row.nodeType == 100) {
                this.showRow = row
                this.isSubject = row.nodeType == 100
                this.isShowForm = true
                this.lessonNodeData.lessonNodeId = row.id
            }
        },
        onDeleteTree(row) {
            this.$refs.treePageBox.loading = true
            this.$http.del('/user/lessons/deleteNode', row.id).then((result) => {
                if (result.code == 1000) {
                    this.loadTree()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.treePageBox.loading = false
            })
        },
        onSaveTree(data, editData) {
            this.$refs.treePageBox.loading = true
            let sendData = JSON.parse(JSON.stringify(data))
            sendData.editNodeId = editData.id
            this.$http.post('/user/lessons/saveNode', sendData).then((result) => {
                if (result.code == 1000) {
                    this.loadTree()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.treePageBox.loading = false
            })
        },
        onMoveTree(row1, row2, type) {
            this.$http.post('/user/lessons/modifyNodeOrder', { modifyId: row1.id, nodeId: row2.id, type }).then(result => {
                if (result.code == 1000) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: '保存失败',
                        type: 'error'
                    })
                }
                this.loadTree()
            })
        },
        loadTree() {
            this.$refs.treePageBox.loading = true
            this.$http.get('/user/lessons/loadTree').then((result) => {
                if (result.code == 1000) {
                    this.$refs.treeNode.setTree(result.data.tree)
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.treePageBox.loading = false
            })
        },
        loadLessonInfo(lessonNodeId) {
            this.lessonsForm = {}
            this.$refs.contentBody.loading = true
            this.$http.get('/user/lessons/loadLessonInfo', { lessonNodeId }).then((result) => {
                if (result.code == 1000) {
                    const lessonsInfo = result.data.lessonsInfo
                    const keys = Object.keys(lessonsInfo)
                    keys.forEach(key => {
                        this.$set(this.lessonsForm, key, lessonsInfo[key])
                    })
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.contentBody.loading = false
            })
        },
        saveLessonsInfo() {
            this.lessonsForm.introduction = this.$refs.introductionEditor?.getContent() || ''
            let lessonsRow = JSON.parse(JSON.stringify(this.lessonsForm))
            this.$refs.contentBody.loading = true
            this.$http.post('/user/lessons/saveLessonInfo', lessonsRow).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        message: '加载成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.contentBody.loading = false
            })
        },
        closeLessonsInfo() {
            this.isShowForm = false
            this.lessonsForm = {}
        },
        toAddFile() {
            this.$refs.addFileWin.isShow = true
        },
        toSetSubjectIconWin() {
            this.$refs.subjectIconWin.isShow = true
        },
        onUploadBefore() {
            this.$refs.addFilePageBox.loading = true
        },
        onUploadFinished(result) {
            if (result && result.code == '1000') {
                this.$refs.addFileWin.isShow = false
                this.$refs.fileTable.refreshData()
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
            this.$refs.lessonFileUploader?.clearFiles()
            this.$refs.addFilePageBox.loading = false
        },
        onUploadIconBefore() {
            this.$refs.setSubjectIconPageBox.loading = true
        },
        onUploadIconFinished(result) {
            const lessonsNodeId = this.showRow.id
            if (result && result.code == '1000') {
                this.$refs.subjectIconWin.isShow = false
                this.$message({
                    message: '保存成功',
                    type: 'success'
                })
                this.loadLessonInfo(lessonsNodeId)
            } else {
                this.$message({
                    message: result.msg,
                    type: 'error'
                })
            }
            this.$refs.subjectIconFileUploader?.clearFiles()
            this.$refs.setSubjectIconPageBox.loading = false
        },
        toAddPaper() {
            this.$refs.addPaperWin.isShow = true
        },
        closeAddPaperWin() {
            this.$refs.addPaperWin.isShow = false
        },
        onOpenAddPaperWin() {
            this.$refs.addPaperTable?.refreshData()
        },
        chosePaper(row) {
            this.$refs.addPaperPageBox.loading = true
            this.$http.post('/user/lessons/saveLessonPaper', { lessonsNodeId: this.lessonNodeData.lessonNodeId, paperId: row.id }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        message: '加载成功',
                        type: 'success'
                    })
                    this.$refs.paperTable.refreshData()
                    this.$refs.addPaperWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.addPaperPageBox.loading = false
            })
        },
        onOpenChoseVideoWin() {
            this.$refs.choseVideoTable?.refreshData()
        },
        toChoseVideo() {
            this.$refs.choseVideoWin.isShow = true
        },
        clearVideo() {
            this.$confirm('此操作将重置该账号的密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                const lessonsNodeId = this.showRow.id
                this.$http.patch('/user/lessons/clearVideoUrl', lessonsNodeId).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.loadLessonInfo(lessonsNodeId)
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
            })
        },
        closeChoseVideoWin() {
            this.$refs.choseVideoWin.isShow = false
        },
        choseVideo(row) {
            const lessonsNodeId = this.lessonNodeData.lessonNodeId
            this.$refs.choseVideoPageBox.loading = true
            this.$http.post('/user/lessons/saveLessonVideo', { lessonsNodeId: lessonsNodeId, videoCode: row.fileCode }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        message: '加载成功',
                        type: 'success'
                    })
                    this.loadLessonInfo(lessonsNodeId)
                    this.$refs.choseVideoWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.choseVideoPageBox.loading = false
            })
        },
        refreshIntroductionEditor() {
            if (this.$refs.introductionEditor) {
                this.$refs.introductionEditor.setContent(this.lessonsForm?.introduction || '')
            }
        },
        introductionEditorReady() {
            this.refreshIntroductionEditor()
        },
        loadVideoClassItems() {
            this.$http.get('/user/vc/classItems').then(result => {
                if (result.code == '1000') {
                    this.videoInfoDataTableParams.searchVideoClass.splice(0, this.videoInfoDataTableParams.searchVideoClass.length)
                    this.videoInfoDataTableParams.searchVideoClass.push({ value: '', label: '' })
                    this.videoInfoDataTableParams.searchVideoClass.push({ value: 0, label: '未分类' })
                    result.data.items.forEach(it => {
                        this.videoInfoDataTableParams.searchVideoClass.push({ value: it.id, label: it.videoClassName })
                    })
                }
            })
        },
        loadPaperClassItems() {
            this.$http.get('/user/pc/classItems').then(result => {
                if (result.code == '1000') {
                    this.paperInfoDataTableParams.searchPaperClass.splice(0, this.paperInfoDataTableParams.searchPaperClass.length)
                    this.paperInfoDataTableParams.searchPaperClass.push({ value: '', label: '' })
                    this.paperInfoDataTableParams.searchPaperClass.push({ value: 0, label: '未分类' })
                    result.data.items.forEach(it => {
                        this.paperInfoDataTableParams.searchPaperClass.push({ value: it.id, label: it.paperClassName })
                    })
                }
            })
        },
        modifyFileOrderNum(row) {
            this.$refs.modifyFileOrderNumWin.isShow = true
            this.fileOrderNumForm.id = row.id
            this.fileOrderNumForm.orderNum = row.orderNum
        },
        saveFileOrderNum() {
            this.$http.patch('/user/lessons/saveFileOrderNum', this.fileOrderNumForm.id, { orderNum: this.fileOrderNumForm.orderNum }).then(result => {
                if (result.code == '1000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.$refs.fileTable.refreshData()
                    this.$refs.modifyFileOrderNumWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        cancelModifyFileOrderNum() {
            this.$refs.modifyFileOrderNumWin.isShow = false
        },
        modifyPaperOrderNum(row) {
            this.$refs.modifyPaperOrderNumWin.isShow = true
            this.paperOrderNumForm.id = row.id
            this.paperOrderNumForm.orderNum = row.orderNum
        },
        savePaperOrderNum() {
            this.$http.patch('/user/lessons/savePaperOrderNum', this.paperOrderNumForm.id, { orderNum: this.paperOrderNumForm.orderNum }).then(result => {
                if (result.code == '1000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.$refs.paperTable.refreshData()
                    this.$refs.modifyPaperOrderNumWin.isShow = false
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        cancelModifyPaperOrderNum() {
            this.$refs.modifyPaperOrderNumWin.isShow = false
        },
    },
    watch: {
        lessonNodeData: {
            deep: true,
            handler(value) {
                this.loadLessonInfo(value.lessonNodeId)
                this.$refs.fileTable?.refreshData()
                this.$refs.paperTable?.refreshData()
                this.$refs.lessonFileUploader?.clearFiles()
            },
        },
        lessonsForm: {
            deep: true,
            handler() {
                this.refreshIntroductionEditor()
            },
        },
    },
    components: {
        LessonsNodeTree,
        PageBox,
        SplitPane,
        DataTable,
        DialogWin,
        UEditor,
    },
    mixins: [ userPms ],
    mounted() {
        this.loadTree()
        this.loadVideoClassItems()
        this.loadPaperClassItems()
    }
}
</script>

<style scoped>
</style>
