import { render, staticRenderFns } from "./VersionRecordPage.vue?vue&type=template&id=57473939&scoped=true&"
import script from "./VersionRecordPage.vue?vue&type=script&lang=ts&"
export * from "./VersionRecordPage.vue?vue&type=script&lang=ts&"
import style0 from "./VersionRecordPage.vue?vue&type=style&index=0&id=57473939&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57473939",
  null
  
)

export default component.exports