<template>
    <div>
        <DialogWin ref="editWin" width="90%" height="90%" :maxWidth="1000" :onOpen="onOpen">
            <PageBox ref="editPageBox">
                <el-form ref="form" :model="questionData.questionForm" :inline="false" label-width="80px">

                    <div>
                        <div style="padding: 10px; font-weight: bold;">
                            <span>题目</span>
                        </div>
                        <div style="padding: 0 20px;">
                            <UEditor ref="questionEditor" :onReady="questionEditorReady" />
                        </div>
                    </div>
                    <div>

                        <!-- 阅读理解 -->
                        <div v-show="isUpdate">
                            <div style="padding: 10px; font-weight: bold;">
                                <span>阅读理解题目</span>
                            </div>
                            <div class="answerBox">

                                <el-table :data="questionData.questionForm.children" style="width: 100%">
                                    <el-table-column type="index" label="序号" width="50" fixed="left" />
                                    <el-table-column prop="questionType" label="题型" width="100">
                                        <template slot-scope="scope">
                                            {{ questionTypeMapping(scope.row.questionType) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="questionText" label="题目" :show-overflow-tooltip="true">
                                        <template slot-scope="scope">
                                            <span>{{ htmlToText(scope.row.questionText) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="120" fixed="right">
                                        <template slot-scope="scope">
                                            <el-button type="primary" size="mini" icon="el-icon-edit" @click="toEditQuestion(questionData.questionGroup, questionData.questionForm, scope.row)"></el-button>
                                            <el-button type="danger" size="mini" icon="el-icon-delete" @click="doDelQuestion(scope.row)"></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <div class="addOptionBtnBox">
                                    <el-button type="primary" size="mini" @click="toEditQuestion(questionData.questionGroup, questionData.questionForm)">添加题目</el-button>
                                </div>
                            </div>
                        </div>

                    </div>
                </el-form>
                <div style="text-align: center; padding: 20px;">
                    <el-button type="primary" @click="saveQuestion">保存</el-button>
                    <el-button @click="onCancelEdit">取消</el-button>
                </div>
            </PageBox>
        </DialogWin>
        <QuestionEditView ref="simpleQuestionEditView" :showQuestionType="true" :onSaved="onSavedQuestion" />
    </div>
</template>

<script lang="ts">
import PageBox from '../components/PageBox'
import DialogWin from '../components/DialogWin'
import UEditor from '../components/UEditor'
import QuestionEditView from '../components/QuestionEditView'

export default {
    name: 'MultipleQuestionEditView',
    props: {
        onCancel: Function,
        onSaved: Function,
        onDeleted: Function,
    },
    data() {
        return {
            updateCount: 0,
            questionData: {
                questionGroup: {},
                questionForm: {},
            },
            isQuestionEditorReady: false,
            questionTypeOptions: [
                { value: 1, label: '填空' },
                { value: 2, label: '判断' },
                { value: 3, label: '单选' },
                { value: 4, label: '多选' },
                { value: 5, label: '不定项选择' },
                { value: 100, label: '阅读理解', hasChildren: true },
            ],
            openCallBack: null,
        }
    },
    computed: {
        isUpdate() {
            return this.questionData?.questionForm?.id > 0
        },
    },
    watch: {
        updateCount() {
            if (this.isQuestionEditorReady) {
                this.$refs.questionEditor?.setContent(this.questionData.questionForm.questionText || '')
            }
        }
    },
    methods: {
        openWin(callback) {
            this.openCallBack = callback
            if (this.$refs.editWin.isShow === true) {
                this.onOpen()
            }
            this.$refs.editWin.isShow = true
        },
        isOpen() {
            return this.$refs.editWin.isShow === true
        },
        setQuestionData(questionGroup, question) {
            let questionGroupData = {}
            let questionData = {}
            if (questionGroup) {
                questionGroupData = JSON.parse(JSON.stringify(questionGroup))
            }
            if (question) {
                questionData = JSON.parse(JSON.stringify(question))
            }
            this.questionData.questionGroup = questionGroupData
            this.questionData.questionForm = questionData
            this.updateCount++
        },
        onOpen() {
            if (this.openCallBack) {
                this.openCallBack(this)
            }
        },
        questionEditorReady() {
            this.isQuestionEditorReady = true
            this.$refs.questionEditor?.setContent(this.questionData.questionForm.questionText || '')
        },
        htmlToText(questionText) {
            let maxLength = 50
            let div = document.createElement('div')
            div.innerHTML = questionText
            let text = div.innerText
            text = text.length <= maxLength ? text : text.substring(0, maxLength) + '...'
            return text
        },
        questionTypeMapping(questionType) {
            let targetArr = this.questionTypeOptions.filter(option => option.value == questionType)
            if (targetArr.length > 0) {
                return targetArr[0].label
            }
            return questionType
        },
        resetQuestionForm() {
        },
        onCancelEdit() {
            this.$refs.editWin.isShow = false
            this.resetQuestionForm()
            if (this.onCancel) {
                this.onCancel()
            }
        },
        doDelQuestion(row) {
            this.$confirm('此操作将永久删除该行记录, 是否继续?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.$refs.editPageBox.loading = true
                this.$http.del('/user/questions/del', row.id).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        })
                        if (this.onDeleted) {
                            this.onDeleted()
                        }
                        //this.reloadData()
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                    this.$refs.editPageBox.loading = false
                })
            }).catch(() => {
            })
        },
        saveQuestion() {
            let data = {
                id: this.questionData.questionForm.id,
                questionGroupId: this.questionData.questionGroup.id,
                questionType: this.questionData.questionGroup.questionSubType,
                questionText: this.$refs.questionEditor.getContent(),
            }
            this.$refs.editPageBox.loading = true
            if (data.id > 0) {
                this.$http.patch('/user/questions/update', data.id, data).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '保存成功!'
                        })
                        if (this.onSaved) {
                            this.onSaved()
                        }
                        this.$refs.editWin.isShow = false
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                    this.$refs.editPageBox.loading = false
                })
            } else {
                this.$http.post('/user/questions/add', data).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '保存成功!'
                        })
                        if (this.onSaved) {
                            this.onSaved()
                        }
                        this.$refs.editWin.isShow = false
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                    this.$refs.editPageBox.loading = false
                })
            }
        },
        toEditQuestion(questionGroup, parentQuestionData, row) {
            let questionData = {}
            if (row) {
                questionData = JSON.parse(JSON.stringify(row))
            }
            if (parentQuestionData) {
                questionData.parentQuestionId = parentQuestionData.id
            }
            this.$refs.simpleQuestionEditView.openWin(view => {
                view.setQuestionData(questionGroup, questionData)
            })
        },
        onSavedQuestion() {
            if (this.onSaved) {
                this.onSaved()
            }
        },
    },
    components: {
        PageBox,
        DialogWin,
        UEditor,
        QuestionEditView,
    },
    mounted() {
    }
}
</script>

<style scoped>
.answerBox {
    padding: 0 20px;
}
.addOptionBtnBox {
    margin-top: 10px;
}
</style>
