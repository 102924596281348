<template>
    <el-container class="body">
        <el-header class="header" :style="headerBoxStyle">
            <span>logo</span>
            <div style="float: right;">
                <el-menu class="el-menu-demo" mode="horizontal"
                    background-color="#409eff"
                    text-color="white"
                    active-text-color="white"
                >
                    <el-menu-item index="1">消息中心</el-menu-item>
                    <el-submenu index="9">
                        <template slot="title">{{user.userNickname}}</template>
                        <el-menu-item index="9-1" @click="showMyInfo">我的信息</el-menu-item>
                        <el-menu-item index="9-8" @click="showModifyPasswordWin">修改密码</el-menu-item>
                        <el-menu-item index="9-9" @click="doLogout">退出</el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
        </el-header>
        <el-container>
            <el-aside class="menu">
                <div :style="menuBoxStyle">
                    <el-menu class="el-menu-vertical-demo" @select="selectMenuItem">
                        <MainMenu :menus="menus" />
                    </el-menu>
                </div>
            </el-aside>
            <el-container>
                <el-main class="main">
                    <div>
                        <el-tabs v-model="editableTabsValue" type="border-card" closable @tab-remove="removeTab">
                            <el-tab-pane
                                v-for="item in editableTabs"
                                :key="item.name"
                                :label="item.label"
                                :name="item.name"
                            >
                                <!--
                                <div class="main-box" :style="{ height: mainBoxHeight }>
                                    <div class="main-content">
                                        <keep-alive>
                                            <router-view></router-view>
                                        </keep-alive>
                                    </div>
                                </div>
                                -->
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="main-box" :style="{ height: mainBoxHeight }">
                        <div class="main-content">
                            <keep-alive :include="keepViews">
                                <router-view></router-view>
                            </keep-alive>
                        </div>
                    </div>
                </el-main>
                <el-footer class="footer" :style="footerBoxStyle">
                   footer
                </el-footer>
            </el-container>
        </el-container>
        <DialogWin ref="modifyPasswordWin" title="修改密码" width="600px" height="400px" :onClose="onCloseModifyPasswordWin">
            <div style="padding: 50px;">
                <el-form ref="modifyPasswordForm" :model="modifyPasswordData" :rules="modifyPasswordRules" label-position="right" label-width="100px">
                    <el-form-item prop="password" label="密码">
                        <el-input v-model="modifyPasswordData.password" autocomplete="off" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="newPassword" label="新密码">
                        <el-input v-model="modifyPasswordData.newPassword" autocomplete="off" placeholder="请输入新密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item prop="newPassword2" label="确认新密码">
                        <el-input v-model="modifyPasswordData.newPassword2" autocomplete="off" placeholder="请输入确认新密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="commitModifyPassword">修改密码</el-button>
                        <el-button @click="hideModifyPasswordWin">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </DialogWin>
        <DialogWin ref="myInfoWin" title="我的信息" :maxWidth="800" :onOpen="onOpenMyInfo">
            <PageBox ref="myInfoBox">
                <el-form :model="myInfo" label-position="right" label-width="100px">
                    <el-form-item label="账号">
                        <el-input :value="myInfo.loginid" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="userName" label="姓名">
                        <el-input :value="myInfo.userName" autocomplete="off" placeholder="请输入姓名" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="userNickname" label="昵称">
                        <el-input v-model="myInfo.userNickname" autocomplete="off" placeholder="请输入昵称"></el-input>
                    </el-form-item>
                    <el-form-item prop="roleName" label="角色">
                        <el-input :value="myInfo.roleName" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="totalMoney" label="余额" v-if="isOrgManager">
                        <el-input-number :value="myInfo.totalMoney" placeholder="余额" :precision="2" disabled></el-input-number>
                    </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="saveMyInfo">保存</el-button>
                        <el-button @click="hideMyInfo">取消</el-button>
                    </div>
                </el-form>
            </PageBox>
        </DialogWin>
    </el-container>
</template>

<script>
import md5 from 'js-md5'
import { menuConfig } from '../menu-config.js'
import { pmsConfig, userPms } from '../user-pms.js'
import DialogWin from '../components/DialogWin'
import PageBox from '../components/PageBox'
import MainMenu from '../components/MainMenu'

export default {
    name: 'HomePage',
    data() {
        return {
            editableTabsValue: 'M-index',
            editableTabs: [
                { name: 'M-index', label: '首页' }
            ],
            keepViews: [ 'WelcomePage' ],
            user: {},
            menus: [],
            modifyPasswordData: {
                password: '',
                newPassword: '',
                newPassword2: '',
            },
            modifyPasswordRules: {
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' },
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' },
                ],
                newPassword2: [
                    { required: true, message: '请输入确认新密码', trigger: 'blur' },
                    { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' },
                ],
            },
            headerBoxStyle: { height: '60px', lineHeight: '60px' },
            footerBoxStyle: { height: '30px', lineHeight: '30px' },
            menuBoxStyle: { height: '100%' },
            mainBoxHeight: '100px',
            myInfo: {
                loginid: '',
                userNickname: '',
                userName: '',
                roleName: '',
                totalMoney: 0,
            },
        }
    },
    computed: {
        isOrgManager() {
            return this.hasPms(pmsConfig.PMS_ACCOUNT_RECRUITER_ADD)
        },
    },
    methods: {
        resize() {
            this.menuBoxStyle.height = (window.innerHeight - parseInt(this.headerBoxStyle.height)) + 'px'
            this.mainBoxHeight = (window.innerHeight
                                         - parseInt(this.headerBoxStyle.height)
                                         - parseInt(this.footerBoxStyle.height)
                                         - 40 - 1) + 'px'
        },
        selectMenuItem(index) {
            if (this.editableTabs.filter(tab => tab.name === index).length <= 0) {
                let menu = this.getMenuInfoByCode(index, this.menus)
                if (menu) {
                    this.editableTabs.push({ name: index, label: menu ? menu.title : '' })
                }
            }
            this.editableTabsValue = index
        },
        getMenuInfoByCode(code, menus) {
            for (var i = 0; i < menus.length; i++) {
                let menu = menus[i]
                if (code == menu.code) {
                    return menu
                }
                if (menu.children && menu.children.length > 0) {
                    menu = this.getMenuInfoByCode(code, menu.children)
                    if (menu) {
                        return menu
                    }
                }
            }
            return null
        },
        removeTab(targetName) {
            if (this.menuConfig[targetName]) {
                let viewName = this.$router.options.routes.filter(route => route.path == this.menuConfig[targetName].uri).pop()?.component?.name
                this.keepViews = this.keepViews.filter(it => it != viewName)
            }
            let tabs = this.editableTabs;
            let activeName = this.editableTabsValue;
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.name === targetName) {
                        let nextTab = tabs[index + 1] || tabs[index - 1];
                        if (nextTab) {
                            activeName = nextTab.name;
                        }
                    }
                });
            }
            this.editableTabsValue = activeName;
            this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        },
        setPage() {
            if (this.$route.path == '/not-find') {
                this.$router.push({
                    path: '/welcome',
                }).catch(error => { error })
            }
        },
        setDefaultTab() {
            for (var key in this.menuConfig) {
                if (this.menuConfig[key]?.uri === this.$route.path) {
                    this.editableTabsValue = key
                    this.selectMenuItem(this.editableTabsValue)
                    break
                }
            }
        },
        doLogout() {
            this.$http.get('/doLogout').then(result => {
                if (result.code == 1000) {
                    this.$bus.$emit('doLogout')
                }
            })
        },
        loadUser() {
            this.$http.get('/user/getUser').then(result => {
                if (result.code == 1000) {
                    this.user = result.data.user
                }
            })
        },
        loadMenus() {
            this.$http.get('/user/menu/getMenuTree').then(result => {
                if (result.code == 1000) {
                    let menus = result.data.menus
                    menus.unshift({ code: 'M-index', title: '首页' })
                    this.menus = menus
                }
                this.setDefaultTab()
            })
        },
        showModifyPasswordWin() {
            this.$refs.modifyPasswordWin.isShow = true
        },
        hideModifyPasswordWin() {
            this.$refs.modifyPasswordWin.isShow = false
        },
        showMyInfo() {
            this.$refs.myInfoWin.isShow = true
        },
        hideMyInfo() {
            this.$refs.myInfoWin.isShow = false
        },
        onOpenMyInfo() {
            this.$refs.myInfoBox.loading = true
            this.$http.get('/user/getMyInfo').then(result => {
                if (result.code == 1000) {
                    this.$message({
                        message: '加载成功',
                        type: 'success'
                    })
                    let myInfo = result.data.info
                    this.myInfo.loginid = myInfo.loginid
                    this.myInfo.userNickname = myInfo.userNickname
                    this.myInfo.userName = myInfo.userName
                    this.myInfo.roleName = myInfo.roleName
                    this.myInfo.totalMoney = myInfo.totalMoney || 0
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.myInfoBox.loading = false
            })
        },
        saveMyInfo() {
            this.$refs.myInfoBox.loading = true
            this.$http.post('/user/saveMyInfo', this.myInfo).then(result => {
                if (result.code == 1000) {
                    this.$message({
                        message: '加载成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.$refs.myInfoBox.loading = false
            })
        },
        commitModifyPassword() {
            this.$refs.modifyPasswordForm.validate((valid) => {
                if (valid) {
                    if (this.modifyPasswordData.newPassword != this.modifyPasswordData.newPassword2) {
                        this.$message({
                            message: '两次输入的密码不同',
                            type: 'warning'
                        })
                        return
                    }
                    this.$http.post('/user/modifyPassword', {
                        password: md5(this.modifyPasswordData.password),
                        newPassword: md5(this.modifyPasswordData.newPassword),
                        newPassword2: md5(this.modifyPasswordData.newPassword2),
                    }).then(result => {
                        if (result.code == 1000) {
                            this.$message({
                                message: '修改密码成功',
                                type: 'success'
                            })
                            this.hideModifyPasswordWin()
                        } else {
                            this.$message({
                                message: result.msg,
                                type: 'error'
                            })
                        }
                    })
                }
            })
        },
        onCloseModifyPasswordWin() {
            this.modifyPasswordData.password = ''
            this.modifyPasswordData.newPassword = ''
            this.modifyPasswordData.newPassword2 = ''
        },
    },
    watch: {
        editableTabsValue(value) {
            let menuInfo = this.menuConfig[value]
            if (menuInfo && menuInfo.uri) {
                let viewName = this.$router.options.routes.filter(route => route.path == menuInfo.uri).pop()?.component?.name
                if (!this.keepViews.includes(viewName)) {
                    this.keepViews.push(viewName)
                }
                if (this.$router.history.current.path != menuInfo.uri) {
                    this.$router.push({
                        path: menuInfo.uri
                    }).catch(error => { error })
                }
            } else {
                this.$router.push({
                    path: '/not-find',
                }).catch(error => { error })
            }
        }
    },
    components: {
        DialogWin,
        PageBox,
        MainMenu,
    },
    mixins: [ menuConfig, userPms ],
    mounted() {
        window.addEventListener('resize', this.resize)
        this.resize()
        this.setPage()
        this.loadUser()
        this.loadMenus()
    }
};
</script>

<style scoped>
.body {
    height: 100%;
}
.header {
    background-color: #409eff;
    color: white;
}
.menu {
    background-color: #F5F7FA;
}
.main {
    padding: 0;
    height: 0px;
    overflow: hidden;
}
.content-body {
    height: 100%;
}
.footer {
    background-color: #DDDDDD;
}
.el-tabs {
    width: 100%;
}
.main-box {
    height: 100%;
    overflow: auto;
    background-color: white;
}
.main-content {
    height: 100%;
    box-sizing: border-box;
}
</style>
