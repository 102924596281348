<template>
    <div id="app">
        <div v-if="loadDone">
            <Login v-if="!isLogin" />
            <Home v-if="isLogin" />
        </div>
    </div>
</template>

<script>
import Login from './pages/Login.vue'
import Home from './pages/Home.vue'

export default {
    name: 'App',
    data() {
        return {
            loadDone: false,
            isLogin: true,
        }
    },
    components: {
        Login,
        Home,
    },
    mounted() {
        this.loadDone = false
        this.$bus.$on('loginDone', (data) => {
            this.isLogin = data
        })
        this.$bus.$on('doLogout', () => {
            this.isLogin = false
            this.$router.replace({
                path: '/'
            }).catch(error => { error })
        })
        this.$http.get('/user/getUser').then(result => {
            if (result.code == 1000) {
                this.isLogin = true
            } else {
                this.isLogin = false
            }
            this.loadDone = true
        })
    },
    beforeDestroy: function() {
        this.$bus.$off('loginDone')
    },
};
</script>

<style>
html {
    height: 100%;
}
body {
    margin: 0;
    height: 100%;
}
#app {
    height: 100%;
}
.win-box {
    height: 600px;
    margin: -30px -20px;
    overflow: auto;
    background-color: #eeeeee;
}
.el-dialog {
    margin: 0 auto 0;
}
.el-tabs--border-card>.el-tabs__content {
    padding: 0;
}
.btn-custom-cancel {
    float: right;
    margin-left: 10px;
}
.el-tabs--border-card>.el-tabs__header {
    border-bottom: none;
}
</style>
