<template>
    <div>
        Not Find
    </div>
</template>

<script lang="ts">
export default {
    name: 'NotFindPage',
}
</script>

<style scoped>
</style>
