<template>
    <div>
        <el-dialog
            @opened="afterOpen"
            @close="beforeClose"
            :title="titleText"
            :top="winTop"
            :width="winWidth"
            :close-on-click-modal="false"
            :visible.sync="isShow"
        >
            <div class="win-box" :style="{height: winHeight}">
                <slot />
            </div>
        </el-dialog>
    </div>
</template>

<script lang="ts">
export default {
    name: 'DialogWin',
    props: {
        title: String,
        width: String,
        height: String,
        minWidth: Number,
        minHeight: Number,
        maxWidth: Number,
        maxHeight: Number,
        onOpen: Function,
        onClose: Function,
    },
    data() {
        return {
            isShow: false,
            titleText: '',
            innerWidth: 800,
            innerHeight: 600,
            winTitleHeight: 54,
        }
    },
    computed: {
        winTop() {
            return parseInt((this.innerHeight - parseInt(this.winHeight) - this.winTitleHeight) / 2.0) + 'px'
        },
        winWidth() {
            let size = parseInt(0.8 * this.innerWidth)
            if (this.width && this.width.endsWith('px')) {
                size = parseInt(this.width)
            }
            if (this.width && this.width.endsWith('%')) {
                size = parseInt(parseInt(this.width) / 100.0 * this.innerWidth)
            }
            let min = parseInt(this.minWidth)
            size = min > 0 && size < min ? min : size
            let max = parseInt(this.maxWidth)
            size = max > 0 && size > max ? max : size
            return size + 'px'
        },
        winHeight() {
            let size = parseInt(0.8 * this.innerHeight)
            if (this.height && this.height.endsWith('px')) {
                size = parseInt(this.height)
            }
            if (this.height && this.height.endsWith('%')) {
                size = parseInt(parseInt(this.height) / 100.0 * this.innerHeight) - 54
            }
            let min = parseInt(this.minHeight)
            size = min > 0 && size < min ? min : size
            let max = parseInt(this.maxHeight)
            size = max > 0 && size > max ? max : size
            return size + 'px'
        },
    },
    methods: {
        doInit() {
            this.titleText = (this.title || '').trim()
            this.titleText = this.titleText ? this.titleText : '标题'
        },
        resize() {
            this.innerWidth = window.innerWidth
            this.innerHeight = window.innerHeight
        },
        afterOpen() {
            if (this.onOpen) {
                this.onOpen()
            }
        },
        beforeClose() {
            if (this.onClose) {
                this.onClose()
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.resize)
        this.resize()
        this.doInit()
    },
}
</script>

<style scoped>
</style>
