<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                ref="orderTable"
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :actions="dataTable.actions"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :activeUrl="dataTable.activeUrl"
                :maxEditWinWidth="800"
                :maxEditWinHeight="250"
                :loadListen="dataTableLoadListen"
                :toAddFun="modifyOrder"
            />
        </PageBox>
        <DialogWin ref="editWin" :onOpen="openEditWin" title="下单">
            <PageBox>
                <el-form ref="form" :model="orderForm" label-width="80px">
                    <el-form-item label="学生账号">
                        <el-input v-model="orderForm.studentLoginid" style="width: 300px;" disabled></el-input>
                        <el-button type="primary" style="margin-left: 10px;" @click="toChoseStudent">选择</el-button>
                    </el-form-item>
                        <el-form-item label="科目">
                            <div style="background-color: #FFF; padding: 20px;" v-loading="treeLoading">
                                <LessonsNodeTree
                                    :showCheckbox="true"
                                    :showMoney="true"
                                    :edit="false"
                                    :onCheck="onCheckTree"
                                    localSaveKey="OrderPageExpandTrees"
                                    ref="treeNode" />
                            </div>
                        </el-form-item>
                        <el-form-item label="总价">
                            <span style="color: #C00;">￥{{ totalMoney }}</span>
                        </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="primary" @click="saveOrder">保存</el-button>
                        <el-button @click="cancelOrder">取消</el-button>
                    </div>
                </el-form>
            </PageBox>
        </DialogWin>
        <DialogWin ref="orderInfoWin" :onOpen="showOrderView">
            <div style="padding: 20px;">
            <el-form ref="form" label-width="80px">
                <el-form-item label="学生账号">
                    <el-input :value="viewRow.studentLoginid" disabled></el-input>
                </el-form-item>
                <el-form-item label="学生账号">
                    <LessonsNodeTree
                        :showCheckbox="true"
                        :showMoney="true"
                        :edit="false"
                        ref="viewTreeNode" />
                </el-form-item>
                <el-form-item label="总价">
                    <span style="color: #C00;">￥{{ viewTotalMoney }}</span>
                </el-form-item>
                <div style="text-align: center;">
                    <el-button type="primary" @click="confirmOrder" v-show="isShowConfirmBtn">审批通过</el-button>
                    <el-button type="danger" @click="toRejectOrder" v-show="isShowRejectBtn">驳回</el-button>
                    <el-button @click="closeView">关闭</el-button>
                </div>
            </el-form>
            </div>
        </DialogWin>
        <DialogWin ref="rejectWin">
            <PageBox>
                <el-form ref="rejectOrderForm" :model="rejectOrderForm" label-width="80px" :rules="rejectOrderRules">
                    <el-form-item label="驳回理由" prop="rejectText">
                        <el-input type="textarea" :rows="5" v-model="rejectOrderForm.rejectText"></el-input>
                    </el-form-item>
                    <div style="text-align: center;">
                        <el-button type="danger" @click="rejectOrder">驳回</el-button>
                        <el-button @click="cancelRejectOrder">取消</el-button>
                    </div>
                </el-form>
            </PageBox>
        </DialogWin>
        <DialogWin ref="choseStudentWin" :onOpen="searchStudent">
            <PageBox ref="choseStudentPageBox">
                <el-form ref="searchStudentForm" :model="searchStudentForm" label-width="100px">
                    <el-form-item label="输入学生账号">
                        <el-input v-model="searchStudentForm.studentLoginid" style="width: 300px;"></el-input>
                        <el-button type="primary" style="margin-left: 10px;" @click="searchStudent">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="studentTableData" style="width: 100%">
                    <el-table-column type="index" label="序号" width="80" fixed="left"></el-table-column>
                    <el-table-column prop="loginid" label="账号"></el-table-column>
                    <el-table-column prop="userName" label="姓名"></el-table-column>
                    <el-table-column prop="userNickname" label="昵称"></el-table-column>
                    <el-table-column  label="选择" width="80" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="choseStudent(scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 20px; text-align: center;">
                    <el-button @click="closeChoseStudentWin">关闭</el-button>
                </div>
            </PageBox>
        </DialogWin>
    </div>
</template>

<script lang="ts">
import { pmsConfig, userPms } from '../user-pms.js'
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'
import DialogWin from '../components/DialogWin'
import LessonsNodeTree from '../components/LessonsNodeTree'

export default {
    name: 'OrderPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/order/list',
                titles: [
                    { prop: 'orderNo', label: '订单号' },
                    { prop: 'studentLoginid', label: '学生账号' },
                    { prop: 'applicantUser', label: '下单人' },
                    //{ prop: 'orderMsg', label: '订单信息' },
                    { prop: 'orderState', label: '订单状态', mapping: {
                        'new': '新建',
                        'review': '审核中',
                        'rejected': '已驳回',
                        'done': '完成',
                    } },
                    //{ prop: 'orderStateMsg', label: '状态说明' },
                    { prop: 'createDate', label: '创建时间' },
                    { prop: 'finishDate', label: '完成时间' },
                    { prop: 'confirmUser', label: '审批人' },
                ],
                //addUrlValue: '/user/userGroup/add',
                //updateUrlValue: '/user/userGroup/update',
                //delUrlValue: '/user/userGroup/del',
                //activeUrlValue: '/user/userGroup/active',
                actionsConfig: [
                    { label: '查看', plain: false, width: 66, func: this.toShowOrder, pms: pmsConfig.PMS_ORDER_LIST },
                    { label: '编辑', type: 'primary', plain: false, width: 66, func: this.modifyOrder, pms: pmsConfig.PMS_ORDER_ADD, isShow: this.showModifyBtn },
                    { label: '提交', type: 'success', plain: false, width: 66, func: this.commitOrder, pms: pmsConfig.PMS_ORDER_ADD, isShow: this.showCommitBtn },
                ],
                actions: [],
                searchFormLines: [
                    { prop: 'orderNo', label: '订单号', type: 'text' },
                    { prop: 'studentLoginid', label: '学生账号', type: 'text' },
                    { prop: 'applicantUser', label: '下单人', type: 'text' },
                    { prop: 'orderState', label: '订单状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'new', label: '新建' },
                        { value: 'review', label: '审核中' },
                        { value: 'rejected', label: '已驳回' },
                        { value: 'done', label: '已完成' }
                    ] },
                    { prop: 'createDateBegin', label: '创建开始', type: 'date' },
                    { prop: 'createDateEnd', label: '创建结束', type: 'date' },

                    //{ prop: 'createDate', label: '创建时间', type: 'date' },
                    //{ prop: 'finishDate', label: '完成时间', type: 'date' },
                    { prop: 'confirmUser', label: '审批人', type: 'text' },

                    /*
                    { prop: 'activeStatus', label: '启用状态', type: 'select', options: [
                        { value: '', label: '' },
                        { value: 'Y', label: '启用' },
                        { value: 'N', label: '禁用' }
                    ] },
                    */
                ],
            },
            orderForm: {
                studentLoginid: '',
            },
            treeLoading: true,
            subjectTableData: [],
            checkedIds: [],
            totalMoney: 0,
            viewRow: {},
            viewTotalMoney: 0,
            rejectOrderForm: {},
            rejectOrderRules: {
                rejectText: [
                    { required: true, message: '请输入驳回理由', trigger: 'blur' },
                ],
            },
            studentTableData: [],
            searchStudentForm: {
                studentLoginid: '',
            },
        }
    },
    computed: {
        isShowConfirmBtn() {
            return this.hasPms(pmsConfig.PMS_ORDER_REVIEW) && this.viewRow.orderState == 'review'
        },
        isShowRejectBtn() {
            return this.hasPms(pmsConfig.PMS_ORDER_REVIEW) && this.viewRow.orderState == 'review'
        },
    },
    methods: {
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
        onUpdateUserPms() {
            this.dataTable.addUrl = this.hasPms(pmsConfig.PMS_ORDER_ADD) ? this.dataTable.addUrlValue : ''
            this.dataTable.updateUrl = this.hasPms(pmsConfig.PMS_ORDER_ADD) ? this.dataTable.updateUrlValue : ''
            this.dataTable.actions = this.dataTable.actionsConfig.filter(action => this.hasPms(action.pms))
            this.$refs.orderTable.showAdd = this.hasPms(pmsConfig.PMS_ORDER_ADD)
        },
        toShowOrder(row) {
            this.viewRow = JSON.parse(JSON.stringify(row))
            this.$refs.orderInfoWin.isShow = true
        },
        showOrderView() {
            let studentLoginid = this.viewRow.studentLoginid
            this.loadTree(studentLoginid, treeData => {
                this.forEachTree(treeData, node => node.disabled = true)
                this.$refs.viewTreeNode.setTree(treeData)
                //this.$refs.viewTreeNode.expandedKeys = [ 0 ]
                let orderContent = JSON.parse(this.viewRow.orderContentJson)
                this.viewTotalMoney = 0
                this.forEachTree(treeData, node => {
                    if (orderContent.subjectIds.includes(node.id)) {
                        this.viewTotalMoney += node.money
                    }
                })
                this.viewTotalMoney = Math.round(this.viewTotalMoney * 100) / 100.0
                setTimeout(() => {
                    this.$refs.viewTreeNode.setCheckedIds(orderContent.subjectIds)
                    this.$refs.viewTreeNode.openNodes(orderContent.subjectIds)
                })
            })
        },
        closeView() {
            this.viewRow = {}
            this.$refs.orderInfoWin.isShow = false
        },
        confirmOrder() {
            this.$confirm('你确定要通过审批吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                let orderId = this.viewRow.id
                this.$http.patch('/user/order/confirm', orderId).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '修改成功!'
                        })
                        this.$refs.orderTable.refreshData()
                        this.$refs.orderInfoWin.isShow = false
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
            })
        },
        toRejectOrder() {
            this.$refs.rejectWin.isShow = true
        },
        cancelRejectOrder() {
            this.$refs.rejectWin.isShow = false
            this.$refs.rejectOrderForm.resetFields()
        },
        rejectOrder() {
            let orderId = this.viewRow.id
            this.$refs.rejectOrderForm.validate((valid) => {
                if (valid) {
                    this.$http.patch('/user/order/reject', orderId, this.rejectOrderForm).then((result) => {
                        if (result.code == 1000) {
                            this.$message({
                                type: 'success',
                                message: '修改成功!'
                            })
                            this.$refs.orderTable.refreshData()
                            this.$refs.rejectWin.isShow = false
                            this.$refs.orderInfoWin.isShow = false
                            this.$refs.rejectOrderForm.resetFields()
                        } else {
                            this.$message({
                                message: result.msg,
                                type: 'error'
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        },
        modifyOrder(row) {
            this.orderForm = row ? row : {}
            this.$refs.editWin.isShow = true
        },
        commitOrder(row) {
            this.$confirm('你确定要提交订单吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass: 'el-button--danger',
                cancelButtonClass: 'btn-custom-cancel',
                type: 'warning',
            }).then(() => {
                this.$http.patch('/user/order/commit', row.id).then((result) => {
                    if (result.code == 1000) {
                        this.$message({
                            type: 'success',
                            message: '修改成功!'
                        })
                        this.$refs.orderTable.refreshData()
                    } else {
                        this.$message({
                            message: result.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
            })
        },
        showModifyBtn(row) {
            return [ 'new', 'rejected' ].includes(row.orderState)
        },
        showCommitBtn(row) {
            return [ 'new', 'rejected' ].includes(row.orderState)
        },
        saveOrder() {
            this.$http.post('/user/order/saveOrder', { id: this.orderForm.id, studentLoginid: this.orderForm.studentLoginid, subjectIds: this.checkedIds }).then((result) => {
                if (result.code == 1000) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    })
                    this.$refs.editWin.isShow = false
                    this.$refs.treeNode.setCheckedIds([])
                    this.totalMoney = 0
                    this.$refs.orderTable.refreshData()
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        cancelOrder() {
            this.$refs.editWin.isShow = false
            this.totalMoney = 0
        },
        toChoseStudent() {
            this.$refs.choseStudentWin.isShow = true
        },
        closeChoseStudentWin() {
            this.$refs.choseStudentWin.isShow = false
        },
        choseStudent(row) {
            this.$set(this.orderForm, 'studentLoginid', row.loginid)
            this.loadUserSubjectsAndSetTree(row.loginid)
            this.closeChoseStudentWin()
        },
        searchStudent() {
            this.$refs.choseStudentPageBox.loading = true
            this.$http.get('/user/order/searchMyStudents', { studentLoginid: this.searchStudentForm.studentLoginid }).then((result) => {
                this.studentTableData.splice(0)
                const students = result.data.page.records
                students?.forEach(student => {
                    student.studentLoginid = student.loginid
                    this.studentTableData.push(student)
                })
                this.$refs.choseStudentPageBox.loading = false
            })
        },
        openEditWin() {
            this.loadTree(this.orderForm.studentLoginid, treeData => {
                this.$refs.treeNode.setTree(treeData)
                //this.$refs.treeNode.expandedKeys = [ 0 ]
                this.loadUserSubjectsAndSetTree(this.orderForm.studentLoginid ? this.orderForm.studentLoginid : '')
            })
        },
        loadTree(studentLoginid, callback) {
            this.treeLoading = true
            this.$http.get('/user/order/loadSubjectTree').then((result) => {
                if (result.code == 1000) {
                    callback(result.data.tree)
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
            })
        },
        loadUserSubjects(studentLoginid, callback) {
            if (!studentLoginid) {
                this.treeLoading = false
                return
            }
            this.$http.get('/user/order/loadUserSubjectIds', { studentLoginid }).then((result) => {
                if (result.code == 1000) {
                    //let userOwnIds = result.data.subjectIds
                    if (callback) {
                        callback(result.data.subjectIds)
                    }
                } else {
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    })
                }
                this.treeLoading = false
            })
        },
        loadUserSubjectsAndSetTree(studentLoginid) {
            this.loadUserSubjects(studentLoginid, subjectIds => {
                this.forEachTree(this.$refs.treeNode.getTree(), node => node.disabled = subjectIds.includes(node.id))
                setTimeout(() => {
                    let orderContent = JSON.parse(this.orderForm.orderContentJson || '{}')
                    let totalCheckedIds = []
                    subjectIds.forEach(id => totalCheckedIds.push(id))
                    orderContent.subjectIds?.forEach(id => totalCheckedIds.push(id))
                    this.$refs.treeNode.setCheckedIds(totalCheckedIds)
                    this.$refs.treeNode.openNodes(totalCheckedIds)
                    this.onCheckTree()
                }, 100)
            })
        },
        forEachTree(nodes, action) {
            if (nodes && nodes.length > 0) {
                nodes.forEach(node => {
                    action(node)
                    this.forEachTree(node.children, action)
                })
            }
        },
        onCheckTree() {
            let checkedIds = this.$refs.treeNode.getCheckedIds()
            let tree = this.$refs.treeNode.getTree()
            let rows = []
            this.treeToList(tree, rows)
            rows = rows.filter(row => checkedIds.includes(row.id) && row.nodeType == 100 && !row.disabled)
            let resultIds = rows.map(row => row.id)
            let moneys = rows.map(row => row.money ? row.money : 0)
            moneys.push(0)
            let totalMoney = moneys.reduce((total, item) => total + item )
            totalMoney = Math.round(totalMoney * 100) / 100.0
            this.checkedIds = resultIds
            this.totalMoney = totalMoney
        },
        treeToList(tree, rows) {
            if (tree) {
                tree.forEach(node => {
                    rows.push(node)
                    this.treeToList(node.children, rows)
                })
            }
        },
    },
    components: {
        PageBox,
        DataTable,
        DialogWin,
        LessonsNodeTree,
    },
    mixins: [ userPms ],
}
</script>

<style scoped>
</style>
