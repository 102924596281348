<template>
    <div style="height: 100%;">
        <PageBox ref="pageBox">
            <DataTable
                :ps="ps"
                :pn="pn"
                :showIndex="dataTable.showIndex"
                :titles="dataTable.titles"
                :dataUrl="dataTable.dataUrl"
                :searchFormLines="dataTable.searchFormLines"
                :editFormLines="dataTable.editFormLines"
                :pageBar="dataTable.pageBar"
                :addUrl="dataTable.addUrl"
                :updateUrl="dataTable.updateUrl"
                :delUrl="dataTable.delUrl"
                :maxEditWinWidth="800"
                :maxEditWinHeight="250"
                :loadListen="dataTableLoadListen"
            />
        </PageBox>
    </div>
</template>

<script lang="ts">
import PageBox from '../components/PageBox'
import DataTable from '../components/DataTable'

export default {
    name: 'VideoClassPage',
    props: {
        ps: Number,
        pn: Number,
    },
    data() {
        return {
            dataTable: {
                showIndex: true,
                pageBar: true,
                dataUrl: '/user/vc/list',
                titles: [
                    { prop: 'videoClassName', label: '分组名称' },
                ],
                addUrl: '/user/vc/add',
                updateUrl: '/user/vc/update',
                delUrl: '/user/vc/del',
                searchFormLines: [
                    { prop: 'videoClassName', label: '分组名称', type: 'text' },
                ],
                editFormLines: [
                    { prop: 'id', label: 'ID', type: 'hidden' },
                    { prop: 'videoClassName', label: '分组名称', type: 'text', rule: { required: true, min: 1, max: 32 } },
                ],
            },
        }
    },
    methods: {
        dataTableLoadListen(requestKeySet) {
            if (this.$refs.pageBox) {
                this.$refs.pageBox.loading = requestKeySet.size > 0
            }
        },
    },
    components: {
        PageBox,
        DataTable,
    },
}
</script>

<style scoped>
</style>
