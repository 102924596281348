<template>
    <div>
        <div v-for="menu in menus" :key="menu.code">
            <el-submenu :index="menu.code" v-if="menu.children && menu.children.length > 0">
                <template slot="title">
                    <i v-if="menuConfig[menu.code] && menuConfig[menu.code]['icon']" :class="menuConfig[menu.code]['icon']"></i>
                    <span slot="title">{{menu.title}}</span>
                </template>
                <MainMenu :menus="menu.children" />
            </el-submenu>
            <el-menu-item :index="menu.code" v-else>
                <template slot="title">
                    <i v-if="menuConfig[menu.code] && menuConfig[menu.code]['icon']" :class="menuConfig[menu.code]['icon']"></i>
                    <span slot="title">{{menu.title}}</span>
                </template>
            </el-menu-item>
        </div>
    </div>
</template>

<script lang="ts">
import { menuConfig } from '../menu-config.js'

export default {
    name: 'MainMenu',
    props: {
        menus: Array,
    },
    mixins: [ menuConfig ],
}
</script>

<style scoped>
</style>
