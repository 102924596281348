import VueRouter from 'vue-router'
import Welcome from '../pages/Welcome'
import NotFind from '../pages/NotFind'
import UserPage from '../pages/UserPage'
import RolePage from '../pages/RolePage'
import RoleHierarchyPage from '../pages/RoleHierarchyPage'
import UserGroupPage from '../pages/UserGroupPage'
import StudentAccountPage from '../pages/StudentAccountPage'
import RecruiterAccountPage from '../pages/RecruiterAccountPage'
import OrgManagerAccountPage from '../pages/OrgManagerAccountPage'
import LessonsPage from '../pages/LessonsPage'
import PaperPage from '../pages/PaperPage'
import VideoPage from '../pages/VideoPage'
import VideoClassPage from '../pages/VideoClassPage'
import PaperClassPage from '../pages/PaperClassPage'
import OrderPage from '../pages/OrderPage'
import VersionRecordPage from '../pages/VersionRecordPage'
import ConfigPage from '../pages/ConfigPage'
import MoneyLogPage from '../pages/MoneyLogPage'
import OrgInfoPage from '../pages/OrgInfoPage'
import OrgCarouselPage from '../pages/OrgCarouselPage'

const router = new VueRouter({
    model: 'hash', // hash, history
    routes: [
        {
            path: '/',
            component: Welcome,
        },
        {
            path: '/welcome',
            component: Welcome,
        },
        {
            path: '/not-find',
            component: NotFind,
        },
        {
            path: '/setting/user-list',
            component: UserPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/setting/role-list',
            component: RolePage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/setting/role-hierarchy',
            component: RoleHierarchyPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/setting/user-group-list',
            component: UserGroupPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/setting/version-list',
            component: VersionRecordPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/setting/config-list',
            component: ConfigPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        // other
        {
            path: '/account/student-list',
            component: StudentAccountPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/account/recruiter-list',
            component: RecruiterAccountPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/account/org-manager-list',
            component: OrgManagerAccountPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/lessons/lessons-list',
            component: LessonsPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/question/paper-list',
            component: PaperPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/question/video-list',
            component: VideoPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/question/video-class',
            component: VideoClassPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/question/paper-class',
            component: PaperClassPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/order/order-list',
            component: OrderPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/moneyLog/money-log-list',
            component: MoneyLogPage,
            props($router) {
                return {
                    ps: parseInt($router.query.ps),
                    pn: parseInt($router.query.pn),
                }
            }
        },
        {
            path: '/org/info',
            component: OrgInfoPage,
        },
        {
            path: '/org/carousel',
            component: OrgCarouselPage,
        },
    ]
})

/*
router.beforeEach((to, from, next) => {
    next()
})

router.afterEach((to, from) => {
})
*/

export default router
