<template>
    <div class="split-pane" ref="splitPane" :style="{ height: height + 'px' }">
        <div class="pane" :style="{ width: leftBoxWidth + 'px', overflow: 'auto' }">
            <slot name="one"></slot>
        </div>
        <div draggable="false" class="split" :style="{ width: splitWidth + 'px' }" @mousedown="startMove"></div>
        <div class="pane" :style="{ width: rightBoxWidth + 'px', overflow: 'auto' }">
            <slot name="two"></slot>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: 'SplitPane',
    props: {
        leftWidth: Number,
        defaultHeight: Number,
    },
    data() {
        return {
            width: 800,
            height: 100,
            leftBoxWidth: 200,
            splitWidth: 10,
            rightBoxWidth: 590,
        }
    },
    methods: {
        updateWidth() {
            this.rightBoxWidth = this.width - this.leftBoxWidth - this.splitWidth
        },
        resize() {
            this.width = this.$refs.splitPane?.clientWidth
            this.width = this.width > 0 ? this.width : 800
            this.updateWidth()
        },
        handleMove(e) {
            this.leftBoxWidth += e.movementX
            this.leftBoxWidth = this.leftBoxWidth >= 0 ? this.leftBoxWidth : 0
            this.updateWidth()
        },
        startMove(e) {
            if (e.button == 0) {
                document.addEventListener('mousemove', this.handleMove)
            }
        },
        endMove() {
            document.removeEventListener('mousemove', this.handleMove)
        },
    },
    mounted() {
        if (parseInt(this.leftWidth) > 0) {
            this.leftBoxWidth = parseInt(this.leftWidth)
        }
        if (parseInt(this.defaultHeight) > 0) {
            this.height = parseInt(this.defaultHeight)
        }
        document.addEventListener('mouseup', this.endMove)
        window.addEventListener('resize', this.resize)
        this.resize()
    },
    beforeDestroy() {
        document.removeEventListener('mouseup', this.endMove)
        window.removeEventListener('resize', this.resize)
    }
}
</script>

<style scoped>
.split-pane {
    display: flex;
}
.split {
    background-color: #EEE;
    width: 10px;
    cursor: col-resize;
}
.pane {
}
</style>
